export const permutations = function(arr: string[]) {
  const output = [] as string[][];
  const categories = new Map();

  arr.forEach(val => {
    let count = categories.get(val) || 0;
    categories.set(val, ++count);
  });
  function permutate(index: number) {
    if (index === arr.length) {
      output.push(([] as string[]).concat(arr));
      return;
    }

    categories.forEach((val, key) => {
      if (val > 0) {
        arr[index] = key;
        categories.set(key, --val);
        permutate(index + 1);
        categories.set(key, ++val);
      }
    });
  }

  permutate(0);
  return output;
};
