
import { Vue, Component, Prop } from "vue-property-decorator";
import { queries } from "@/apollo";
import { customMoment } from "@/main";
import { User, DdPage } from "@/CodegenTypes";
import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import { startCase, sortBy, groupBy, uniq } from "lodash";
import PageDetailsModal from "../bet-details/PageDetailsModal.vue";
import DeletePageModal from "../bet-details/DeletePageModal.vue";

type ProcessedPage = {
  id: string;
  name: string;
  enteredFrom: string;
  enteredById: string;
  createdAt: string;
  releaseDates: string[];
  calculation: {
    bigCount: number;
    smallCount: number;
    rawCost: number;
  };
}

@Component({
  apollo: {
    user: {
      query: queries.userPages,
      fetchPolicy: "no-cache",
      skip() {
        return !this.userId || !this.value;
      },
      variables() {
        return {
          releaseDateStart: this.releaseDateStart,
          releaseDateEnd: this.releaseDateEnd,
          behalfOf: this.userId
        };
      }
    }
  },
  components: {
    PageDetailsModal,
    DeletePageModal
  }
})
export default class ViewBetModal extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ required: true }) userId!: boolean;
  user = null as null | User;

  selectedPage = null as null | ProcessedPage
  showPageDetailModal = false;
  showDeletePageModal = false;

  releaseDateStart = customMoment()
    .startOf("day")
    .toDate();

  releaseDateEnd = customMoment()
    .add(2, "weeks")
    .startOf("day")
    .toDate();

  startCase = startCase;
  formatCount = formatCount;
  formatDollar = formatDollar;

  get processedPages(): ProcessedPage[] {

    const pages = Object.entries(groupBy(this.user?.pages, p => p.id)).map(([pageId, pages]) => {

      return {
        ...pages[0],
        releaseDates: uniq(pages.map(p => p.releaseDate)),
        calculation: pages.reduce((acc, page) => {
          return {
            bigCount: acc.bigCount + page.calculation.bigCount,
            smallCount: acc.smallCount + page.calculation.smallCount,
            rawCost: acc.rawCost + page.calculation.rawCost
          };
        }, {
          bigCount: 0,
          smallCount: 0,
          rawCost: 0
        })
      }
    })

    return sortBy(pages, p => -customMoment(p.createdAt).unix());
  }

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }

  openPage(page: ProcessedPage) {
    this.selectedPage = page;
    this.showPageDetailModal = true;
  }

  handleSubmit() {
    this.toggleModal(!this.value);
  }

  formatDatetime(dateStr: string | Date) {
    return customMoment(dateStr).fromNow()
  }
  formatDate(dateStr: string | Date) {
    return customMoment(dateStr).format("DD/MM/YYYY");
  }
  formatReleaseDate(dateStr: string | Date) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY");
  }

  deletePage(pageId: string) {
    this.selectedPage = this.processedPages.find(p => p.id === pageId) || null;
    if (!this.selectedPage) {
      return;
    }
    this.showDeletePageModal = true;
  }

  reloadAll() {
    Object.values(this.$apollo.queries).forEach(q => q.refetch());
  }

  handleCopy(id: DdPage["id"]) {
    const page = this.user?.pages.find(p => p.id === id);
    if (!page) {
      return;
    }
    this.$emit("copy", page);
    this.toggleModal(false);
    // this.selectedPage = page;
    // this.showPageDetailModal = true;
  }
}
