import { User } from "@/CodegenTypes";
import { sortBy } from "lodash";

export const getDownlinesTree = (upline: User, downlines: User[]): User => {
  const foundDownlines = downlines.filter(u => u.uplineId === upline.userId);
  const result = foundDownlines.map(downline =>
    getDownlinesTree(downline, downlines)
  );
  return {
    ...upline,
    downlines: sortBy(result, d => d.userId)
  };
};
