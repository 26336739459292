import { TTEntry } from "@/types";
import { compact, sumBy } from "lodash";
import { calculateTtEntry } from "./calculateTtEntry";

export const calculateTtEntries = (entries: TTEntry[]) => {
  const agg = compact(
    entries.map(entry => {
      return calculateTtEntry(entry);
    })
  );

  return {
    d1: sumBy(agg, a => a.d1),
    d2: sumBy(agg, a => a.d2),
    d3: sumBy(agg, a => a.d3),
    d4: sumBy(agg, a => a.d4),
    total: sumBy(agg, a => a.total)
  };
};
