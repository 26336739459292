
import { Vue, Component, Watch } from "vue-property-decorator";
import TTMassEntryTable2 from "@/components/modules/entry/TTMassEntryTable2.vue";
import TTEditRowsModal, {
  TTEditForm,
} from "@/components/modules/entry/TTEditRowsModal.vue";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import { queries, mutations } from "@/apollo";
import {
  Release,
  AppMode,
  User,
  StoredTtPage,
  WindowSettings,
} from "@/CodegenTypes";
import { TTEntryInput, TTDay } from "@/types";
import { parseTtEntryInputs } from "@/helpers/tt/parseTtEntryInputs";

import { clamp, compact, flatten } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { errorMutationNotification } from "@/utils";
import { customMoment } from "@/main";
import { calculateTtEntries } from "@/helpers/tt/calculateTtEntries";
import { formatTimeSetting } from "@/helpers/formatTimeSetting";
import { getTtValidDayOptions } from "@/helpers/getTtValidDayOptions";
import { useRootStore } from "@/store/pinia";

@Component({
  components: {
    TTMassEntryTable2,
    TTEditRowsModal,
    AccountSelectModal,
  },
  apollo: {
    releases: {
      query: queries.releases,
      variables() {
        return {
          appMode: AppMode.Tt,
        };
      },
    },
    windowSettings: {
      query: queries.windowSettings,
      variables() {
        const appMode = useRootStore().appMode as AppMode;
        return {
          appMode: appMode,
        };
      },
    },

    user: {
      query: queries.userWithCreditSummary,
      skip() {
        return !this.selectedAccountId;
      },
      variables() {
        return {
          userId: this.selectedAccountId,
        };
      },
    },
    storedTtPages: {
      query: queries.userStoredTtPages,
      variables() {
        return {
          storedPageType: "fixed",
          includeDownlines: true,
          userId: this.selectedAccountId,
        };
      },
      update(data) {
        return data.user.storedTtPages;
      },
    },
  },
})
export default class TTFixedBet extends Vue {
  selectedPageId = null as null | StoredTtPage["id"];
  windowSettings = null as null | WindowSettings;

  storedTtPages = null as null | StoredTtPage[];

  releases = null as null | Release[];
  selectedDayIndex = 0;
  page = "";
  user = null as null | User;
  showEditModal = false;

  isSubmitting = false;
  showAccountSelectionModal = false;

  formatTimeSetting = formatTimeSetting;

  editForm = {
    rowsFrom: undefined,
    rowsTo: undefined,
    unit: undefined,
    mode: undefined,
  } as TTEditForm;

  formatDollar = formatDollar;

  MAX_ROWS = 40;

  tables = [{ startingIndex: 0 }, { startingIndex: 20 }];

  entries = [] as TTEntryInput[];

  get selectedAccount() {
    return this.user;
  }

  get selectedAccountId() {
    return this.$route.query.userId;
  }

  get creditForDisplay() {
    return {
      creditGiven: this.selectedAccount?.creditGiven || 0,
      creditUsed: this.selectedAccount?.creditSummary.creditUsed || 0,
      creditBalance: this.selectedAccount?.creditSummary.creditBalance || 0,
    };
  }

  get selectedDay() {
    if (this.releases) {
      return this.dayOptions[this.selectedDayIndex].value as TTDay;
    } else {
      return 1;
    }
  }

  set selectedDay(val) {
    const index = this.dayOptions.findIndex(
      (opt) => opt.value === val
    ) as TTDay;

    this.selectedDayIndex = index;
  }

  get upcomingReleases() {
    return this.releases?.filter((release) => !release.isOver);
  }

  async mounted() {
    const result = await this.$apollo.query<{ releases: Release[] }>({
      query: queries.releases,
      variables: {
        appMode: useRootStore().appMode,
      },
    });
    this.releases = result.data.releases;
    const priorityIndex = this.dayOptions.findIndex(
      (opt) => opt.defaultPriority
    );
    Vue.set(this, "selectedDayIndex", priorityIndex === -1 ? 0 : priorityIndex);
    for (let i = 0; i < this.MAX_ROWS; i++) {
      this.entries.push({
        day: this.selectedDay,
        number1: "",
        number2: "",
        number3: "",
        number4: "",
        number5: "",
        number6: "",
        number7: "",
        number8: "",
        number9: "",
        number10: "",
        unit: undefined,
        mode: undefined,
      });
    }
    const pageIdQuery = this.$route.query.pageId;
    if (typeof pageIdQuery === "string") {
      this.selectedPageId = pageIdQuery;
    }
  }

  get validEntries() {
    return compact(parseTtEntryInputs(this.entries));
  }

  get calculations() {
    return calculateTtEntries(compact(this.validEntries));
  }

  get pageOptions() {
    const defaultOption = { label: "-- New --", value: null };

    if (!this.storedTtPages) {
      return [defaultOption];
    }
    const allOptions = this.storedTtPages.map((page) => {
      return {
        label: `[${page.userId.toUpperCase()}, ${page.username}] ${page.name}`,
        value: page.id,
      };
    });

    return [defaultOption, ...allOptions];
  }

  get validDayOptions() {
    if (!this.releases) {
      return [];
    }
    const validOptions = getTtValidDayOptions(this.releases);
    return validOptions;
  }

  get dayOptions() {
    const OPTIONS = [
      // { label: "1=<Mon>", value: 1, defaultPriority: false },
      { label: "4=<Thu>", value: 4, defaultPriority: false },
      // { label: "5=<Mon,Fri>", value: 5, defaultPriority: false },
    ];

    return OPTIONS.filter((o) => this.validDayOptions.includes(o.value));
  }

  get selectedPage() {
    if (!this.storedTtPages) {
      return null;
    }
    return this.storedTtPages.find((p) => p.id === this.selectedPageId);
  }

  @Watch("selectedPage")
  fillWithPage() {
    const page = this.selectedPage;
    this.doClearEntries();
    if (!page) {
      this.page = "";
      return;
    } else {
      this.page = page.name;
    }

    const newEntries = [...this.entries];
    for (const [index, entry] of page.entries.entries()) {
      const numbers = entry.numbersText.split("-");
      newEntries[index].number1 = numbers[0];
      newEntries[index].number2 = numbers[1] || "";
      newEntries[index].number3 = numbers[2] || "";
      newEntries[index].number4 = numbers[3] || "";
      newEntries[index].number5 = numbers[4] || "";
      newEntries[index].number6 = numbers[5] || "";
      newEntries[index].number7 = numbers[6] || "";
      newEntries[index].number8 = numbers[7] || "";
      newEntries[index].number9 = numbers[8] || "";
      newEntries[index].number10 = numbers[9] || "";
      newEntries[index].unit = entry.unit;
      newEntries[index].mode = entry.mode || undefined;
    }
    this.entries = newEntries;
  }

  // get totalCost() {
  //   const costs = this.validEntries.map(entry => {
  //     return calculateEntryCost(entry);
  //   });
  //   const bigCosts = costs.reduce((sum, cost) => {
  //     sum += cost.big;
  //     return sum;
  //   }, 0);
  //   const smallCosts = costs.reduce((sum, cost) => {
  //     sum += cost.small;
  //     return sum;
  //   }, 0);

  //   return {
  //     big: bigCosts,
  //     small: smallCosts
  //   };
  // }

  toggleEditModal() {
    this.showEditModal = !this.showEditModal;
  }

  getDayFromDate(dateStr: string) {
    const day = customMoment(dateStr).isoWeekday();
    return day;
  }

  changeSelectedDay(newVal: TTDay) {
    for (const entry of this.entries) {
      entry.day = newVal;
    }
  }

  focus(field: "number" | "big" | "small", inputTargetIndex: number) {
    let targetIndex = inputTargetIndex;
    if (targetIndex < 0) {
      targetIndex = 59;
    } else if (targetIndex > 59) {
      targetIndex = 0;
    }
    const tablesEles = this.$refs["mass-entry-table"] as any;
    for (const [i, table] of this.tables.entries()) {
      const startingIndex = table.startingIndex;
      if (targetIndex >= startingIndex && targetIndex < startingIndex + 20) {
        tablesEles[i].focus(field, targetIndex);
      }
    }
    return;
  }

  performEditRows(editForm: TTEditForm) {
    if (!editForm.rowsFrom || !editForm.rowsTo) {
      return;
    }

    // make sure in range
    const rowsFrom = clamp(editForm.rowsFrom, 0, this.MAX_ROWS);
    const rowsTo = clamp(editForm.rowsTo, 0, this.MAX_ROWS);

    // make sure rowsFrom is smaller than rowsTo
    const range = [rowsFrom, rowsTo].sort((a, b) => a - b);

    for (let i = range[0] - 1; i <= range[1] - 1; i++) {
      this.entries[i].unit = editForm.unit;
      this.entries[i].mode = editForm.mode;
    }
    this.editForm = {
      rowsFrom: undefined,
      rowsTo: undefined,
      unit: undefined,
      mode: undefined,
    };
    this.showEditModal = false;
  }

  async clearEntries() {
    const { isOk } = await this.$dialog.confirm({
      title: "Clear the form?",
      text: "All existing inputs will be deleted.",
      icon: "question",
    });

    if (!isOk) {
      return;
    }
    this.doClearEntries();
  }

  doClearEntries() {
    const newEntries = [];

    for (let i = 0; i < this.MAX_ROWS; i++) {
      newEntries.push({
        day: this.selectedDay,
        number1: "",
        number2: "",
        number3: "",
        number4: "",
        number5: "",
        number6: "",
        number7: "",
        number8: "",
        number9: "",
        number10: "",
        unit: undefined,
        mode: undefined,
      });
    }
    this.entries = newEntries;
  }

  // TODO
  // validateAllAndShowError() {
  //   const errors = [];
  //   for (const [index, entry] of this.entries.entries()) {
  //     const validNumber = validateNumber(entry);
  //     const noSmall = (entry.small as any) === "" || entry.small === undefined;
  //     const noBig = (entry.big as any) === "" || entry.big === undefined;
  //     const hasMode = entry.mode;
  //     if (entry.number === "" && noSmall && noBig && !hasMode) {
  //       continue;
  //     }
  //     if (!validNumber) {
  //       errors.push(`Row ${index + 1} has invalid number`);
  //     }
  //     if (validNumber && noSmall && noBig) {
  //       errors.push(`Row ${index + 1} has no big and small`);
  //     }
  //   }
  //   return errors;
  // }

  async handleSubmit() {
    // const errors = this.validateAllAndShowError();
    // if (errors.length) {
    //   this.$dialog.alert({
    //     text: errors[0]
    //   });
    //   return;
    // }
    if (!this.selectedAccountId) {
      this.$dialog.alert({
        text: "No account selected",
      });
      return;
    }
    const entries = this.validEntries;

    if (!this.validEntries.length) {
      this.$dialog.alert({
        text: "Please enter number details for entries",
      });
      return;
    }

    const { isOk } = await this.$dialog.confirm({
      title: "Confirm?",
      text: "Are you sure to submit the entries for TOTO betting?",
      icon: "info",
    });

    if (isOk) {
      try {
        this.isSubmitting = true;
        await this.$apollo.mutate({
          mutation: mutations.insertTtEntry,
          variables: {
            page: {
              page: this.page,
              entries,
              userId: this.selectedAccountId,
              entrySource: "massEntry",
            },
          },
        });
        this.$apollo.queries.storedTtPages.refetch();

        this.$dialog.alert({
          text: "Entry submitted successfully.",
        });
        this.doClearEntries();
        this.page = "";
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    }
  }

  async handleDelete() {
    if (!this.selectedAccountId) {
      this.$dialog.alert({
        text: "No account selected",
      });
      return;
    }

    const { isOk } = await this.$dialog.confirm({
      title: "Confirm?",
      text: "Are you sure to delete the saved bet?",
      icon: "info",
    });

    if (isOk) {
      try {
        this.isSubmitting = true;
        await this.$apollo.mutate({
          mutation: mutations.deleteSavedTtPage,
          variables: {
            id: this.selectedPage?.id,
          },
        });
        this.$apollo.queries.storedTtPages.refetch();
        this.$dialog.alert({
          text: "Saved bet deleted.",
        });
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    }
  }
  async handleSave() {
    if (!this.selectedAccountId) {
      this.$dialog.alert({
        text: "No account selected",
      });
      return;
    }
    const entries = this.validEntries;

    if (!this.validEntries.length) {
      this.$dialog.alert({
        text: "Please enter number details for entries",
      });
      return;
    }

    const { isOk } = await this.$dialog.confirm({
      title: "Confirm?",
      text: "Are you sure to save the entries for SGTOTO betting?",
      icon: "info",
    });

    if (isOk) {
      try {
        this.isSubmitting = true;
        const { data } = await this.$apollo.mutate({
          mutation: mutations.saveTtEntry,
          variables: {
            page: {
              id: this.selectedPage?.id || undefined,
              page: this.page,
              entries,
              userId: this.selectedAccountId,
              storedPageType: "fixed",
            },
          },
        });
        this.selectedPageId = data.saveTtEntry;
        this.$apollo.queries.storedTtPages.refetch();
        this.$dialog.alert({
          text: "Entry saved successfully.",
        });
        this.fillWithPage();
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    }
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD MMM YYYY");
  }
}
