import { TtMode } from "@/CodegenTypes";
import combinations from "combinations";
import { chain, compact } from "lodash";

export const getTtEntriesRaw = (entry: {
  number1: string;
  number2: string;
  number3: string;
  number4: string;
  number5: string;
  number6: string;
  number7: string;
  number8: string;
  number9: string;
  number10: string;
  unit: number;
  mode: TtMode;
}) => {
  const rawNumbers = [
    entry.number1,
    entry.number2,
    entry.number3,
    entry.number4,
    entry.number5,
    entry.number6,
    entry.number7,
    entry.number8,
    entry.number9,
    entry.number10
  ];
  const numbers = chain(rawNumbers)
    .compact()
    .sortBy(n => n)
    .sortedUniq()
    .value();
  const numbersText = numbers.join("-");
  if (!entry.mode) {
    return [
      {
        numbers,
        numbersText,
        digits: numbers.length,
        unit: entry.unit
      }
    ];
  }
  const combs = combinations(numbers);

  if (entry.mode === TtMode.R) {
    return combs
      .filter(
        comb => comb.length === 2 || comb.length === 3 || comb.length === 4
      )
      .map(numberCombination => ({
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombination.length,
        unit: entry.unit
      }));
  } else if (entry.mode === TtMode.R2) {
    return combs
      .filter(comb => comb.length === 2)
      .map(numberCombination => ({
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombination.length,
        unit: entry.unit
      }));
  } else if (entry.mode === TtMode.R3) {
    return combs
      .filter(comb => comb.length === 3)
      .map(numberCombination => ({
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombination.length,
        unit: entry.unit
      }));
  } else if (entry.mode === TtMode.R4) {
    return combs
      .filter(comb => comb.length === 4)
      .map(numberCombination => ({
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombination.length,
        unit: entry.unit
      }));
  } else if (entry.mode === TtMode.R5) {
    return combs
      .filter(comb => comb.length === 2 || comb.length === 3)
      .map(numberCombination => ({
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombination.length,
        unit: entry.unit
      }));
  } else if (entry.mode === TtMode.R7) {
    return combs
      .filter(comb => comb.length === 3 || comb.length === 4)
      .map(numberCombination => ({
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombination.length,
        unit: entry.unit
      }));
  } else if (entry.mode === TtMode.TwoWild) {
    const numberCombinations = [];
    const baseNumbers = [numbers[0]];
    for (let i = 1; i < numbers.length; i++) {
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.ThreeWild) {
    const numberCombinations = [];
    const baseNumbers = [numbers[0], numbers[1]];
    for (let i = 1; i < numbers.length; i++) {
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.FourWild) {
    const numberCombinations = [];
    const baseNumbers = [numbers[0], numbers[1], numbers[2]];
    for (let i = 1; i < numbers.length; i++) {
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.Wild2) {
    const numberCombinations = [];
    const baseNumbers = [numbers[0]];
    const baseNumbersAsNum = baseNumbers.map(num => +num);
    for (let i = 1; i <= 49; i++) {
      if (baseNumbersAsNum.includes(i)) {
        continue;
      }
      const number = i.toString().padStart(2, "0");
      numberCombinations.push([...baseNumbers, number]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: compact(numberCombination),
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.Wild3) {
    const numberCombinations = [];
    const baseNumbers = [numbers[0], numbers[1]];
    const baseNumbersAsNum = baseNumbers.map(num => +num);
    for (let i = 1; i <= 49; i++) {
      if (baseNumbersAsNum.includes(i)) {
        continue;
      }
      const number = i.toString().padStart(2, "0");
      numberCombinations.push([...baseNumbers, number]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: compact(numberCombination),
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.Wild4) {
    const numberCombinations = [];
    const baseNumbers = [numbers[0], numbers[1], numbers[2]];
    const baseNumbersAsNum = baseNumbers.map(num => +num);
    for (let i = 1; i <= 49; i++) {
      if (baseNumbersAsNum.includes(i)) {
        continue;
      }
      const number = i.toString().padStart(2, "0");
      numberCombinations.push([...baseNumbers, number]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: compact(numberCombination),
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.To) {
    const start = Number(numbers[0]);
    const end = Number(numbers[1]);
    const numberCombinations = [];
    for (let i = start; i <= end; i++) {
      if (i % 2 !== 0) {
        numberCombinations.push([i.toString()]);
      }
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.Te) {
    const start = Number(numbers[0]);
    const end = Number(numbers[1]);
    const numberCombinations = [];
    for (let i = start; i <= end; i++) {
      if (i % 2 === 0) {
        numberCombinations.push([i.toString()]);
      }
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else if (entry.mode === TtMode.Tt) {
    const start = Number(numbers[0]);
    const end = Number(numbers[1]);
    const numberCombinations = [];
    for (let i = start; i <= end; i++) {
      numberCombinations.push([i.toString()]);
    }
    return numberCombinations.map(numberCombination => {
      return {
        numbers: numberCombination,
        numbersText: numberCombination.join("-"),
        digits: numberCombinations.length,
        unit: entry.unit
      };
    });
  } else {
    return [
      {
        numbers,
        numbersText,
        digits: numbers.length,
        unit: entry.unit
      }
    ];
  }
};
