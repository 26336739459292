import { apolloClient } from "@/apollo";
import { AppMode, RoleName } from "@/CodegenTypes";
import { i18n } from "@/plugins/i18n";
import { createPinia, defineStore, PiniaVuePlugin } from "pinia";
import Vue from "vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import decode from "jwt-decode";
import { apolloClientV2 } from "@/apollo/v2";

type RootState = {
  refreshToken: string | null;
  accessToken: string | null;
  appMode: AppMode;
  languageLocale: string;
};

export const useRootStore = defineStore("root", {
  persist: {
    storage: localStorage,
    paths: ["refreshToken", "accessToken", "appMode", "languageLocale"],
  },
  state: () =>
    ({
      refreshToken: null,
      accessToken: null,
      appMode: AppMode.Dd,
      languageLocale: "en",
    } as RootState),

  actions: {
    logout() {
      apolloClient.cache.reset();
      apolloClientV2.cache.reset();
      this.accessToken = null;
      this.refreshToken = null;
    },
    switchAppMode(payload: AppMode) {
      this.appMode = payload;
    },
    setLanguage(locale: string) {
      this.languageLocale = locale;
      i18n.locale = locale;
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!(state.accessToken && state.refreshToken);
    },
    account(state) {
      if (!state.accessToken) return null;
      const accessTokenDetails = decode(state.accessToken);

      return accessTokenDetails as {
        exp: number;
        iat: number;
        id: string;
        isSuperUser: boolean;
        lineageIds: string[];
        managerId: string | null;
        roleName: RoleName;
        tokenType: string;
        userId: string;
        username: string;
      };
    },
  },
});

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
