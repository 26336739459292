import { Entry } from "@/types";
import { countNumberX } from "./countNumberX";
import { countPermutations } from "./countPermutations";

export const calculateEntryCost = (entry: Entry) => {
  const mode = entry.mode;

  let dayMultiplier = 1;
  if (entry.day == 0) {
    dayMultiplier = 3;
  } else if (entry.day == 2) {
    dayMultiplier = 2;
  }

  const xCount = countNumberX(entry.number);
  if (xCount) {
    const xPerm = Math.pow(10, xCount);
    return {
      big: entry.big * 1.6 * xPerm * dayMultiplier,
      small: entry.small * 0.7 * xPerm * dayMultiplier,
    };
  } else if (!mode || mode === "i") {
    return {
      big: entry.big * 1.6 * dayMultiplier,
      small: entry.small * 0.7 * dayMultiplier,
    };
  } else if (mode === "R") {
    const permutations = countPermutations(entry.number);
    return {
      big: entry.big * permutations * 1.6 * dayMultiplier,
      small: entry.small * permutations * 0.7 * dayMultiplier,
    };
  } else if (mode === "k") {
    return {
      big: entry.big * dayMultiplier,
      small: entry.small * dayMultiplier,
    };
  } else {
    throw new Error("unhandled mode: " + mode);
  }
};
