import { TTEntry } from "@/types";
import { compact } from "lodash";

export const getTtNumbers = (entry: Partial<TTEntry>) => {
  const numbers = [
    entry.number1,
    entry.number2,
    entry.number3,
    entry.number4,
    entry.number5,
    entry.number6,
    entry.number7,
    entry.number8,
    entry.number9,
    entry.number10
  ];

  return compact(numbers);
};
