import { Entry } from "@/types";
import { sum } from "lodash";

export const calcISmallTotal = (entries: Entry[]) => {
  const smalls = entries.map((entry) => {
    let dayMultiplier = 1;
    if (entry.day == 0) {
      dayMultiplier = 3;
    } else if (entry.day == 2) {
      dayMultiplier = 2;
    }

    if (!entry.mode) {
      return 0;
    } else if (entry.mode === "R") {
      return 0;
    } else if (entry.mode === "i") {
      return entry.small * dayMultiplier;
    } else if (entry.mode === "k") {
      return 0;
    } else {
      throw new Error("unhandled mode: " + entry.mode);
    }
  });
  return sum(smalls);
};
