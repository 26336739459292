import { TTEntryInput } from "@/types";
import { compact, uniq } from "lodash";
import { validateTTNumber } from "./validateTTNumber";

export const validateTTNumbers = (
  entry: Partial<TTEntryInput>,
  checkMode = true
) => {
  const numbers = [
    entry.number1,
    entry.number2,
    entry.number3,
    entry.number4,
    entry.number5,
    entry.number6,
    entry.number7,
    entry.number8,
    entry.number9,
    entry.number10,
  ];
  const compactNumbers = compact(numbers);
  if (!compactNumbers.length) {
    return false;
  }

  // ensure number is 01 to 49
  for (const [index, number] of compactNumbers.entries()) {
    const valid = validateTTNumber(number);
    if (!valid) {
      console.debug("number must be 01 to 49");
      return false;
    }
  }

  // check gap
  let hasEmpty = false;
  for (const [index, number] of numbers.entries()) {
    if (hasEmpty && number) {
      console.debug("has empty gap");
      return false;
    }
    if (!number) {
      hasEmpty = true;
    }
  }

  // check duplicates
  const uniqCompactNumbers = uniq(compactNumbers);
  if (compactNumbers.length !== uniqCompactNumbers.length) {
    console.debug("has duplicate");
    return false;
  }
  if (checkMode) {
    if (
      entry.mode &&
      ["R", "R2", "R3", "R4", "R5", "R7"].includes(entry.mode) &&
      compactNumbers.length < 3
    ) {
      console.debug("R must have at least 3 numbers");
      return false;
    }
  }
  if (!entry.mode && compactNumbers.length > 4) {
    console.debug("Normal must have at most 4 numbers");
    return false;
  }

  return true;

  // // sort by order

  // mode check
  // TODO
};
