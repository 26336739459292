import { Release } from "@/CodegenTypes";
import { DAY_MAPPING } from "@/constants";

export const getValidDayOptions = (releases: Release[]) => {
  const daysInReleases = releases
    .filter((r) => !r.isOver)
    .map((r) => {
      const day = r.releaseDay;
      if (day === 0) {
        return 7;
      }
      return day;
    });
  const relevantSelections = Object.entries(DAY_MAPPING)
    .map(([key, value]) => {
      // check if value has something daysInReleases does not
      const hasUnincludedDay = value.filter((d) => !daysInReleases.includes(d));
      if (!hasUnincludedDay.length) {
        return +key;
      }
    })
    .filter((selection) => selection !== null);
  return relevantSelections;
};
