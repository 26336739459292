import { Entry } from "@/types";
import { sum } from "lodash";
import { countNumberX } from "../countNumberX";
import { countPermutations } from "../countPermutations";

export const calcSmallTotal = (entries: Entry[]) => {
  const smalls = entries.map((entry) => {
    let dayMultiplier = 1;
    if (entry.day == 0) {
      dayMultiplier = 3;
    } else if (entry.day == 2) {
      dayMultiplier = 2;
    }

    const number = parseInt(entry.number);
    const xCount = countNumberX(entry.number);
    if (xCount) {
      const perms = Math.pow(10, xCount);
      return entry.small * perms * dayMultiplier;
    } else if (!entry.mode) {
      return (entry.small || 0) * dayMultiplier;
    } else if (!entry.number || number < 0 || number > 9999) {
      return 0;
    } else if (entry.mode === "R") {
      return (
        countPermutations(entry.number) * (entry.small || 0) * dayMultiplier
      );
    } else if (entry.mode === "i") {
      return 0;
    } else if (entry.mode === "k") {
      return entry.small * 1.42857142857 * dayMultiplier;
    } else {
      throw new Error("unhandled mode: " + entry.mode);
    }
  });
  return sum(smalls);
};
