import { TTEntry } from "@/types";
import combinations from "combinations";
import { getTtNumbers } from "./getTtNumbers";

export const calculateTtEntry = (entry: TTEntry | null) => {
  if (!entry) {
    return null;
  }

  const numbers = getTtNumbers(entry);
  let numberCombinations = [] as string[][];
  if (!entry.mode) {
    numberCombinations = [numbers];
  } else if (entry.mode === "R") {
    numberCombinations = combinations(numbers).filter(
      (c) => c.length === 2 || c.length === 3 || c.length === 4
    );
  } else if (entry.mode === "R2") {
    numberCombinations = combinations(numbers).filter((c) => c.length === 2);
  } else if (entry.mode === "R3") {
    numberCombinations = combinations(numbers).filter((c) => c.length === 3);
  } else if (entry.mode === "R4") {
    numberCombinations = combinations(numbers).filter((c) => c.length === 4);
  } else if (entry.mode === "R5") {
    numberCombinations = combinations(numbers).filter(
      (c) => c.length === 2 || c.length === 3
    );
  } else if (entry.mode === "R7") {
    numberCombinations = combinations(numbers).filter(
      (c) => c.length === 3 || c.length === 4
    );
  } else if (entry.mode === "twoWild") {
    const baseNumbers = [numbers[0]];
    for (let i = 1; i < numbers.length; i++) {
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
  } else if (entry.mode === "threeWild") {
    const baseNumbers = [numbers[0], numbers[1]];
    for (let i = 2; i < numbers.length; i++) {
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
  } else if (entry.mode === "fourWild") {
    const baseNumbers = [numbers[0], numbers[1], numbers[2]];
    for (let i = 3; i < numbers.length; i++) {
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
  } else if (entry.mode === "wild2") {
    const baseNumbers = [numbers[0]];
    for (let i = 1; i <= 49; i++) {
      if (baseNumbers.includes(i.toString())) {
        continue;
      }
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
  } else if (entry.mode === "wild3") {
    const baseNumbers = [numbers[0], numbers[1]];
    for (let i = 1; i <= 49; i++) {
      if (baseNumbers.includes(i.toString())) {
        continue;
      }
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
  } else if (entry.mode === "wild4") {
    const baseNumbers = [numbers[0], numbers[1], numbers[2]];
    for (let i = 1; i <= 49; i++) {
      if (baseNumbers.includes(i.toString())) {
        continue;
      }
      const number = numbers[i];
      numberCombinations.push([...baseNumbers, number]);
    }
  } else if (entry.mode === "TO") {
    const start = Number(numbers[0]);
    const end = Number(numbers[1]);

    for (let i = start; i <= end; i++) {
      if (i % 2 !== 0) {
        numberCombinations.push([i.toString()]);
      }
    }
  } else if (entry.mode === "TE") {
    const start = Number(numbers[0]);
    const end = Number(numbers[1]);

    for (let i = start; i <= end; i++) {
      if (i % 2 === 0) {
        numberCombinations.push([i.toString()]);
      }
    }
  } else if (entry.mode === "TT") {
    const start = Number(numbers[0]);
    const end = Number(numbers[1]);

    for (let i = start; i <= end; i++) {
      numberCombinations.push([i.toString()]);
    }
  }
  let dayMultiplier = 1;
  if (entry.day == 5) {
    dayMultiplier = 2;
  }
  console.log(dayMultiplier, entry.day);

  return {
    d1:
      numberCombinations.filter((c) => c.length === 1).length *
      entry.unit *
      dayMultiplier,
    d2:
      numberCombinations.filter((c) => c.length === 2).length *
      entry.unit *
      dayMultiplier,
    d3:
      numberCombinations.filter((c) => c.length === 3).length *
      entry.unit *
      dayMultiplier,
    d4:
      numberCombinations.filter((c) => c.length === 4).length *
      entry.unit *
      dayMultiplier,
    total: numberCombinations.length * entry.unit * dayMultiplier,
    combinations: numberCombinations,
  };
};
