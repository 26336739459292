export const stringRouteQuery = (
  query: string | (string | null)[]
): string | null => {
  if (typeof query === "string") {
    return query;
  } else if (query.length > 0) {
    return query[0] || "";
  } else {
    return null;
  }
};
