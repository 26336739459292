export function printNumCountName(numCount: number) {
  switch (numCount) {
    case 1:
      return "A1";
    case 2:
      return "T2";
    case 3:
      return "T3";
    case 4:
      return "T4";
    default:
      throw new Error("Unexpected numCount: " + numCount);
  }
}
