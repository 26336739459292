import { StoredTtPage } from "@/CodegenTypes";
import { first, sumBy } from "lodash";

export const summariseStoredPage = (storedPage: StoredTtPage) => {
  const entries = storedPage.entries;

  const firstEntry = first(entries);
  if (!firstEntry) {
    throw new Error("No first entry");
  }

  const entriesCalculated = entries.map(entry => {
    const day = entry.day;
    let dayMultiplier = 1;
    if (day === 5) {
      dayMultiplier = 2;
    }

    //  TODO: mode multiplier
    const modeMultiplier = 1;
    const modeCount = 1;

    return {
      ...entry,
      unitCalculated: entry.unit * dayMultiplier * modeMultiplier,
      count: modeCount * dayMultiplier
    };
  });
  const unitTotal = sumBy(entriesCalculated, e => e.unitCalculated);

  const count = sumBy(entriesCalculated, e => e.count);

  return {
    day: firstEntry.day,
    unitTotal,
    count
  };
};
