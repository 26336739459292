const ROLE_CREATE_PERMISSION = {
  admin: ["manager"],
  manager: ["agent", "member"],
  agent: ["agent", "member"],
  member: []
} as { [s: string]: string[] };

export const getRoleCreatePermission = (roleName: string) => {
  const allowedRoles = ROLE_CREATE_PERMISSION[roleName];
  return allowedRoles;
};
