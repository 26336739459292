import { Entry } from "@/types";
import { calcBigTotal } from "./calcBigTotal";
import { calcIBigTotal } from "./calcIBigTotal";
import { calcISmallTotal } from "./calcISmallTotal";
import { calcSmallTotal } from "./calcSmallTotal";

export const calculateEntries = (entries: Entry[]) => {
  return {
    bigCount: calcBigTotal(entries),
    smallCount: calcSmallTotal(entries),
    iBigCount: calcIBigTotal(entries),
    iSmallCount: calcISmallTotal(entries),
  };
};
