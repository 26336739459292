import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  fromPromise,
} from "@apollo/client/core";

import { onError } from "@apollo/client/link/error";

import router from "@/router";
import { useRootStore } from "@/store/pinia";
import { debouncedAddWarnToast } from "@/vue-service";

// import mutations from './mutations';
// import { TokenRefreshLink } from 'apollo-link-token-refresh';

const GRAPHQL_ENDPOINT = process.env.VUE_APP_GRAPHQL_ENDPOINT;
if (!GRAPHQL_ENDPOINT) {
  throw new Error("Missing GRAPHQL_ENDPOINT");
}
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: GRAPHQL_ENDPOINT,
});

const fetchRefreshToken = async () => {
  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      operationName: "refreshToken",
      variables: {
        refreshToken: useRootStore().refreshToken,
      },
      query:
        "mutation refreshToken($refreshToken: String!) {\n  refreshAccessToken(refreshToken: $refreshToken) {\n    accessToken\n    success\n    message\n    __typename\n  }\n}\n",
    }),
  });
  return response.json();
};

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    const unauthenticatedError = graphQLErrors.find(
      (error) => error?.extensions?.code === "UNAUTHENTICATED"
    );
    const refreshToken = useRootStore().refreshToken;
    if (unauthenticatedError && refreshToken) {
      console.log("Refreshing access token.");
      return fromPromise(
        fetchRefreshToken().catch((error) => {
          // Handle token refresh errors e.g clear stored tokens, redirect to login
          console.error("fetchRefreshToken error");
        })
      ).flatMap((data: any) => {
        // Do retry
        // debugger;
        if (data.data?.refreshAccessToken?.success) {
          const accessToken = data.data?.refreshAccessToken?.accessToken;
          useRootStore().accessToken = accessToken;
          const oldHeaders = operation.getContext().headers;
          // modify the operation context with a new token
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
            },
          });

          // retry the request, returning the new observable
          return forward(operation);
        } else {
          useRootStore().logout();
          debouncedAddWarnToast("Your login has expired. Please login again.");
          router.push({ name: "index" });
          return forward(operation);
        }
      });
    }
  }

  // if (graphQLErrors)
  //   graphQLErrors.map(({ message, locations, path }) =>
  //     console.log(
  //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //     )
  //   );
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = useRootStore().accessToken;
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  return forward(operation);
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = ApolloLink.from([authMiddleware, errorLink, httpLink]);

// Create the apollo client
export const apolloClientV2 = new ApolloClient({
  link,
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case "User":
          return object.userId as string;
        default:
          return false;
      }
    },
  }),
  //   connectToDevTools: true,
});

export { default as queries } from "./queries";
export { default as mutations } from "./mutations";
