
import { Vue, Component, Watch } from "vue-property-decorator";
import { queries } from "@/apollo";
import { StakeFiles, User } from "@/CodegenTypes";
import { first, sumBy } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { customMoment } from "@/main";

import Clusterize from "clusterize.js";

import pdfMake from "pdfmake";
import pdfMakeFont from "pdfmake/build/vfs_fonts";
import { formatCount } from "@/helpers/formatCount";

type SortType =
  | "number-asc"
  | "number-desc"
  | "amount-asc"
  | "amount-desc"
  | "big-asc"
  | "big-desc"
  | "small-asc"
  | "small-desc";

@Component({
  apollo: {
    me: {
      query: queries.me,
      fetchPolicy: "cache-first",
    },
    stakeFile: {
      query: queries.stakeFile,
      skip() {
        return !this.selectedStartReleaseDate;
      },
      variables() {
        return {
          releaseDate: this.selectedStartReleaseDate,
        };
      },
    },
  },
})
export default class StakeFile extends Vue {
  stakeFile = null as null | StakeFiles;
  loadedAt = new Date();

  me = null as null | User;
  numberFilter = "";

  generatingPdf = false;

  clusterize = null as Clusterize | null;

  sortMode = "number-asc" as SortType;

  formatDollar = formatDollar;

  selectedBetTypeId = "companyFinalStake" as keyof StakeFiles;

  betTypes = [
    { name: "Master Bets", id: "masterStake" },
    { name: "Downline Intake", id: "downlineEatStake" },
    { name: "Company Out", id: "outsetStake" },
    { name: "Company Final", id: "companyFinalStake" },
  ] as { name: string; id: keyof StakeFiles }[];

  get selectedBetType() {
    return this.betTypes.find((bt) => bt.id === this.selectedBetTypeId);
  }

  get sortedRows() {
    if (!this.selectedStake) {
      return [];
    }
    let rowsDataSorted = this.selectedStake;
    if (this.sortMode === "number-asc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => a.number - b.number);
    } else if (this.sortMode === "number-desc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => b.number - a.number);
    } else if (this.sortMode === "amount-asc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => a.amount - b.amount);
    } else if (this.sortMode === "amount-desc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => b.amount - a.amount);
    } else if (this.sortMode === "big-asc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => a.big - b.big);
    } else if (this.sortMode === "big-desc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => b.big - a.big);
    } else if (this.sortMode === "small-asc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => a.small - b.small);
    } else if (this.sortMode === "small-desc") {
      rowsDataSorted = rowsDataSorted.sort((a, b) => b.small - a.small);
    }
    return rowsDataSorted;
  }

  get renderedRows() {
    const rows = this.sortedRows?.map((row) => {
      return `
      <tr>
        <td class="text-center">${row.number}</td>
            <td>${formatCount(row.big)}</td>
            <td>${formatCount(row.small)}</td>
            <td>${formatCount(row.amount)}</td>
      </tr>
      `;
    });
    return rows;
  }

  @Watch("renderedRows")
  doClusterize() {
    if (this.clusterize) {
      this.clusterize?.update(this.renderedRows);
    } else {
      this.clusterize = new Clusterize({
        rows: this.renderedRows,
        show_no_data_row: false,
        scrollId: "scrollArea",
        contentId: "contentArea",
        rows_in_block: 20,
      });
    }
  }

  get selectedStartReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  get stakeTotal() {
    if (!this.stakeFile) {
      return null;
    }

    return {
      masterStake: {
        big: sumBy(this.stakeFile.masterStake, (d) => d.big),
        small: sumBy(this.stakeFile.masterStake, (d) => d.small),
        count: Object.values(this.stakeFile.masterStake).filter(
          (v) => v.big || v.small
        ).length,
        amount: sumBy(
          this.stakeFile.masterStake,
          (d) => d.big * 1.6 + d.small * 0.7
        ),
      },
      downlineEatStake: {
        big: sumBy(this.stakeFile.downlineEatStake, (d) => d.big),
        small: sumBy(this.stakeFile.downlineEatStake, (d) => d.small),
        count: Object.values(this.stakeFile.downlineEatStake).filter(
          (v) => v.big || v.small
        ).length,
        amount: sumBy(
          this.stakeFile.downlineEatStake,
          (d) => d.big * 1.6 + d.small * 0.7
        ),
      },
      companyFinalStake: {
        big: sumBy(this.stakeFile.companyFinalStake, (d) => d.big),
        small: sumBy(this.stakeFile.companyFinalStake, (d) => d.small),
        count: Object.values(this.stakeFile.companyFinalStake).filter(
          (v) => v.big || v.small
        ).length,
        amount: sumBy(
          this.stakeFile.companyFinalStake,
          (d) => d.big * 1.6 + d.small * 0.7
        ),
      },
      outsetStake: {
        big: sumBy(this.stakeFile.outsetStake, (d) => d.big),
        small: sumBy(this.stakeFile.outsetStake, (d) => d.small),
        count: Object.values(this.stakeFile.outsetStake).filter(
          (v) => v.big || v.small
        ).length,
        amount: sumBy(
          this.stakeFile.outsetStake,
          (d) => d.big * 1.6 + d.small * 0.7
        ),
      },
    } as {
      [id: string]: {
        big: number;
        small: number;
        count: number;
        amount: number;
      };
    };
  }

  get selectedStake() {
    const selectedStake = this.stakeFile?.[this.selectedBetTypeId];

    let stake = selectedStake;
    if (this.numberFilter) {
      stake = selectedStake?.filter((s) =>
        s.number.includes(this.numberFilter)
      );
    }
    return stake
      ?.map((s) => ({
        ...s,
        amount: s.big * 1.6 + s.small * 0.7,
      }))
      .filter((s) => s.big || s.small || s.amount);
  }

  exportCsv() {
    const selectedStake = this.stakeFile?.[this.selectedBetTypeId];
    if (!selectedStake) {
      return;
    }
    let exportText = ``;

    exportText += `,,,This file is for analyze use only. As CSV/Excel files can be edited it cannot be used as proof. \r\n`;
    exportText += `,,,We will not take responsibility for data in CSV/Excel downloads. \r\n`;
    exportText += `,,,To download stake proof use Download Pdf. \r\n`;
    exportText += `Number,Big,Small\r\n`;

    selectedStake.forEach((row) => {
      exportText += `${row.number},${row.big.toFixed(2)},${row.small.toFixed(
        2
      )}`;
      exportText += `\r\n`;
    });
    const data = new Blob([exportText], { type: "text/plain" });

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    // if (textFile !== null) {
    //   window.URL.revokeObjectURL(textFile);
    // }

    const textFileUrl = window.URL.createObjectURL(data);

    const link = document.createElement("a");
    link.href = textFileUrl;
    const betTypeName = this.betTypes.find(
      (bt) => bt.id === this.selectedBetTypeId
    );
    const fileName = betTypeName?.name || "stake";
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exportPdf() {
    if (!this.selectedStake || !this.selectedStartReleaseDate || !this.me) {
      return;
    }
    const fileName = this.selectedBetType?.name;
    const releaseDateStr = this.formatDate(this.selectedStartReleaseDate);
    // const reportType = this.selectedRelease.locked
    //   ? "Final"
    //   : "Bet In Progress";
    const pdfMakeObj: any = pdfMake;
    pdfMakeObj.vfs = pdfMakeFont.pdfMake.vfs;
    const docDef = {
      pageMargins: [40, 60, 40, 60],
      pageSize: {
        width: 595.28,
        height: 191200,
      },
      watermark: {
        text: `${releaseDateStr}`,
        opacity: 0.1,
      },
      header: {
        columns: [
          { text: `${fileName}` },
          { text: `Draw: ${releaseDateStr}`, alignment: "right" },
        ],
        margin: [40, 20],
      },
      footer: (currentPage: number, pageCount: number) => ({
        columns: [
          `Generated @ ${this.formatDatetime(
            this.loadedAt
          )} by ${this.me?.userId.toUpperCase()}`,
          { text: `Pg ${currentPage} of ${pageCount}`, alignment: "right" },
        ],
        margin: [20, 20],
      }),
      content: [
        {
          table: {
            layout: "lightHorizontalLines", // optional
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ["*", "*", "*", "*"],
            body: [
              [
                { text: "Number", alignment: "center" },
                { text: "Big", alignment: "right" },
                { text: "Small", alignment: "right" },
                { text: "Amount", alignment: "right" },
              ],
              ...this.selectedStake.map((stake) => [
                { text: stake.number, alignment: "center" },
                { text: formatCount(stake.big), alignment: "right" },
                { text: formatCount(stake.small), alignment: "right" },
                { text: formatDollar(stake.amount), alignment: "right" },
              ]),
              [
                {
                  text: `Total (${
                    this.stakeTotal?.[this.selectedBetTypeId].count || 0
                  })`,
                  alignment: "center",
                },
                {
                  text: this.formatCount(
                    this.stakeTotal?.[this.selectedBetTypeId].big || 0
                  ),
                  alignment: "right",
                },
                {
                  text: this.formatCount(
                    this.stakeTotal?.[this.selectedBetTypeId].small || 0
                  ),
                  alignment: "right",
                },
                {
                  text: this.formatDollar(
                    this.stakeTotal?.[this.selectedBetTypeId].amount || 0
                  ),
                  alignment: "right",
                },
              ],
            ],
          },
        },
      ],
    };
    pdfMakeObj.createPdf(docDef).download(fileName + ".pdf");
  }

  formatCount(numberStr: number) {
    return numberStr.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY");
  }
  formatDatetime(date: Date) {
    return customMoment(date).format("ddd, DD/MM/YYYY hh:mm:ss A");
  }
}
