import { fixRounding } from "./fixRounding";
import { isZeroNegative } from "./isZeroNegative";

export const formatDollar = (number?: number | null) => {
  if (number === undefined || number === null) {
    return "";
  }

  let processedNumber = fixRounding(number, 2);
  processedNumber = isZeroNegative(processedNumber) ? 0 : processedNumber;

  return processedNumber.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};
