
import { Vue, Component } from "vue-property-decorator";

import { queries, mutations } from "@/apollo";
import { User, Release, AppMode, StoredDdPage } from "@/CodegenTypes";

import { parseAra } from "@/helpers/ara/parseAra";
import { summariseStoredPage } from "@/helpers/ara/summariseStoredPage";

import { formatDollar } from "@/helpers/formatDollar";
import { errorMutationNotification } from "@/utils";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import { getValidDayOptions } from "@/helpers/getValidDayOptions";
import { Day } from "@/types";
import { DAY_MAPPING } from "@/constants";
import { customMoment } from "@/main";
import { useRootStore } from "@/store/pinia";

@Component({
  apollo: {
    releases: {
      query: queries.releases,
      variables() {
        return {
          appMode: useRootStore().appMode,
        };
      },
    },
    user: {
      query: queries.userWithCreditSummary,
      skip() {
        return !this.selectedAccountId;
      },
      variables() {
        return {
          userId: this.selectedAccountId,
        };
      },
    },
    storedPages: {
      query: queries.userStoredPages,
      variables() {
        return {
          storedPageType: "upload",
          includeDownlines: true,
          userId: this.selectedAccountId,
        };
      },
      update(data) {
        return data.user.storedPages;
      },
    },
  },
  components: {
    AccountSelectModal,
  },
})
export default class Upload extends Vue {
  showAccountSelectionModal = false;

  releases = null as null | Release[];
  user = null as null | User;

  storedPages = null as null | StoredDdPage[];

  isSubmitting = false;

  selectedDayIndex = 0;

  // uploaded = [] as {
  //   file: File;
  //   content: string;
  //   releases: Release[];
  //   uploadedAt: Date;
  //   page: string;
  //   uploadedBy: User["userId"];
  //   behalfOf: User["userId"];

  //   // warnings: { [s: string]: boolean };
  // }[];
  resetFileUploadKey = Symbol();

  formatDollar = formatDollar;

  get openReleases() {
    return this.releases?.filter((release) => release.windowIsOpen);
  }

  get selectedAccountId() {
    return this.$route.query.userId;
  }

  get selectedAccount() {
    return this.user;
  }

  get validDayOptions() {
    if (!this.releases) {
      return [];
    }
    const validOptions = getValidDayOptions(this.releases);
    return validOptions;
  }

  get dayOptions() {
    const OPTIONS = [
      { label: "0=<Wed, Sat, Sun>", value: 0, defaultPriority: false },
      { label: "2=<Sat, Sun>", value: 2, defaultPriority: false },
      { label: "3=<Wed>", value: 3, defaultPriority: true },
      { label: "6=<Sat>", value: 6, defaultPriority: true },
      { label: "7=<Sun>", value: 7, defaultPriority: true },
    ];

    return OPTIONS.filter((o) => this.validDayOptions.includes(o.value));
  }

  get selectedDay() {
    if (this.releases) {
      return this.dayOptions[this.selectedDayIndex].value as Day;
    } else {
      return 0;
    }
  }

  set selectedDay(val) {
    const index = this.dayOptions.findIndex((opt) => opt.value === val) as Day;

    this.selectedDayIndex = index;
  }

  get selectedReleases() {
    const days = DAY_MAPPING[this.selectedDay];
    return this.releases?.filter((r) => days.includes(r.releaseDay));
  }

  get summarisedUploads() {
    return this.storedPages?.map((storedPage) => ({
      ...storedPage,
      summary: summariseStoredPage(storedPage),
      // summary: summariseAra(storedPage.content, this.selectedDay),
      warnings: {
        noContainUserId:
          !!this.user &&
          !storedPage.uploadFileName?.toLowerCase().includes(this.user.userId),
        fileNameExists:
          this.storedPages &&
          this.storedPages?.filter(
            (p) => p.uploadFileName === storedPage.uploadFileName
          ).length > 1,
      },
    }));
  }

  formatReleasesToText(releases: Release[]) {
    const releaseDates = releases.map((r) => r.releaseDate);
    const releaseDateTexts = releaseDates.map((rd) => this.formatDate(rd));

    return releaseDateTexts.join(" & ");
  }

  handleFileUpload({ files }: { files: File[] }) {
    const file = files[0];
    const reader = new FileReader();
    reader.addEventListener("load", async (event) => {
      const line = event.target?.result;

      if (!this.selectedReleases || !this.selectedAccountId) {
        throw new Error("Missing variables");
      }

      try {
        const parsedAras = parseAra(line as string, this.selectedDay);
        if (parsedAras.length === 0) {
          throw new Error("No valid entries found");
        }
        const firstParsedAra = parsedAras[0];
        await this.$apollo.mutate({
          mutation: mutations.saveDdEntry,
          variables: {
            page: {
              // id: this.selectedPageId || undefined,
              page: firstParsedAra.page,
              entries: parsedAras.map((parsedAra) => ({
                day: this.selectedDay,
                number: parsedAra.number,
                big: parsedAra.big,
                small: parsedAra.small,
                mode: parsedAra.mode,
              })),
              uploadMeta: {
                fileName: file.name,
                fileSize: file.size,
              },
              userId: this.selectedAccountId,
              storedPageType: "upload",
            },
          },
        });
        this.resetFileUploadKey = Symbol();
        this.reloadAll();

        this.$dialog.alert({
          title: "File uploaded successfully",
          text: "You can see your upload below. Click on green load button to confirm.",
        });
      } catch (err) {
        errorMutationNotification(err);
        // this.removeFile(this.uploaded.length - 1, false);
      }
    });
    if (file) {
      reader.readAsText(file);
    }
  }

  formatDatetime(date: Date) {
    return customMoment(date).format("DD MMM YYYY hh:mm:ss A");
  }
  formatDate(date: Date) {
    return customMoment(date).format("ddd, DD MMM YYYY");
  }

  async submitFile(index: number) {
    const targetUpload = this.storedPages?.[index];
    if (!targetUpload) {
      return;
    }
    try {
      // do submit
      this.isSubmitting = true;

      await this.$apollo.mutate({
        mutation: mutations.insertDdEntry,
        variables: {
          page: {
            page: targetUpload.name,
            userId: this.selectedAccountId,
            entries: targetUpload.entries.map((e) => {
              return {
                number: e.number,
                day: e.day,
                big: e.big,
                small: e.small,
                mode: e.mode,
              };
            }),
            entrySource: "upload",
          },
        },
      });
      this.reloadAll();
      this.$dialog.alert({
        text: "Upload successfully loaded into bet list.",
      });
      await this.handleDelete(targetUpload.id, false);
      // this.$dialog.alert({
      //   text: "Upload deleted.",
      // });

      // this.removeFile(index, false);
    } catch (err) {
      errorMutationNotification(err);
    } finally {
      this.isSubmitting = false;
    }
  }
  // async removeFile(index: number, doPrompt = true) {
  //   if (doPrompt) {
  //     const { isOk } = await this.$dialog.confirm({
  //       title: "Remove file?",
  //       text: ""
  //     });

  //     if (!isOk) {
  //       return;
  //     }
  //   }
  //   this.uploaded.splice(index, 1);
  // }

  async handleDelete(pageId: string, doPrompt = true) {
    if (!this.selectedAccountId) {
      this.$dialog.alert({
        text: "No account selected",
      });
      return;
    }

    if (doPrompt) {
      const { isOk } = await this.$dialog.confirm({
        title: "Confirm?",
        text: "Are you sure to delete the upload?",
        icon: "info",
      });
      if (!isOk) {
        return;
      }
    }

    try {
      this.isSubmitting = true;
      await this.$apollo.mutate({
        mutation: mutations.deleteSavedDdPage,
        variables: {
          id: pageId,
        },
      });
      this.reloadAll();
      if (doPrompt) {
        this.$dialog.alert({
          text: "Upload deleted.",
        });
      }
    } catch (err) {
      errorMutationNotification(err);
    } finally {
      this.isSubmitting = false;
    }
  }

  reloadAll() {
    Object.values(this.$apollo.queries).forEach((q) => q.refetch());
  }

  async mounted() {
    const priorityIndex = this.dayOptions.findIndex(
      (opt) => opt.defaultPriority
    );
    Vue.set(this, "selectedDayIndex", priorityIndex === -1 ? 0 : priorityIndex);

    const releaseResult = await this.$apollo.query<{ releases: Release[] }>({
      query: queries.releases,
      variables: {
        appMode: useRootStore().appMode,
      },
    });
    this.releases = releaseResult.data.releases;
  }
}
