
import { Vue, Component, Prop } from "vue-property-decorator";
import { TTEntryInput } from "@/types";

export type TTEditForm = {
  rowsFrom: undefined | number;
  rowsTo: undefined | number;
  unit: undefined | TTEntryInput["unit"];
  mode: undefined | TTEntryInput["mode"];
};

@Component
export default class TTEditRowsModal extends Vue {
  @Prop({ default: false }) value!: boolean;

  editForm = {
    rowsFrom: undefined,
    rowsTo: undefined,
    unit: undefined,
    mode: undefined
  } as TTEditForm;

  modeSelectOptions = [
    { label: "Not Edit", value: undefined },
    { label: "R", value: "R" }
  ];

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }

  handleSubmit() {
    const form = this.editForm;
    if (!form.rowsFrom || !form.rowsTo) {
      return;
    }
    if (!form.unit && !form.mode) {
      return;
    }

    this.$emit("update", this.editForm);
    this.editForm.rowsFrom = undefined;
    this.editForm.rowsTo = undefined;
    this.editForm.unit = undefined;
    this.editForm.mode = undefined;

    this.toggleModal(false);
  }
}
