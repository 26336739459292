import { Entry, EntryInput } from "@/types";
import { compact } from "lodash";
import { validateNumber } from "./validateNumber";

export const parseEntryInputs = (entries: EntryInput[]): Entry[] => {
  const validEntries = entries.map(entryInput => {
    if (!validateNumber(entryInput)) {
      return null;
    }
    const big = Number(entryInput.big) || 0;
    const small = Number(entryInput.small) || 0;

    const mode = entryInput.mode === "" ? undefined : entryInput.mode;

    if (big === 0 && small === 0) {
      return null;
    }
    return {
      ...entryInput,
      mode,
      big,
      small
    };
  });

  return compact(validEntries) as Entry[];
};
