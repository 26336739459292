
import { Vue, Component } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import PageDetailsModal from "@/components/modules/bet-details/PageDetailsModal.vue";
import RenamePageModal from "@/components/modules/bet-details/RenamePageModal.vue";
import DeletePageModal from "@/components/modules/bet-details/DeletePageModal.vue";
import DownlineSummary from "@/components/modules/bet-details/DownlineSummary.vue";
import { queries, mutations } from "@/apollo";
import { User, DdPage, StoredPageType, AppMode } from "@/CodegenTypes";

import { sumBy, startCase, flatten, first } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { formatCount } from "@/helpers/formatCount";
import { errorMutationNotification } from "@/utils";
import { customMoment } from "@/main";
import { FEATURE_TOGGLES } from "@/config";

@Component({
  apollo: {
    user: {
      query: queries.userPagesSummary,
      skip() {
        return !this.selectedStartReleaseDate;
      },
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          releaseDateStart: customMoment(this.selectedStartReleaseDate)
            .startOf("day")
            .toDate(),
          releaseDateEnd: customMoment(this.selectedStartReleaseDate)
            .startOf("day")
            .toDate(),
          behalfOf: this.$route.query.userId,
        };
      },
    },
  },
  components: {
    PageDetailsModal,
    RenamePageModal,
    DeletePageModal,
    Tabs,
    DownlineSummary,
  },
})
export default class PageDetails extends Vue {
  user = null as null | User;

  showAccountSelectionModal = false;

  FEATURE_TOGGLES = FEATURE_TOGGLES;

  startCase = startCase;

  formatDollar = formatDollar;
  formatCount = formatCount;
  AppMode = AppMode;

  selectedPage = null as null | DdPage;
  showPageDetailModal = false;
  showRenamePageModal = false;
  showDeletePageModal = false;

  tabs = [
    { id: "bet summary", name: "Bet Summary" },
    { id: "bet details", name: "Bet Details" },
  ];

  get selectedStartReleaseDate() {
    const query = this.$route.query.startReleaseDate;
    return Array.isArray(query) ? first(query) : query;
  }

  get pageDetailTotal() {
    const data = this.pageDetailsData;
    return {
      big: sumBy(data, (d) => d.big).toFixed(2),
      small: sumBy(data, (d) => d.small).toFixed(2),
      amount: formatDollar(sumBy(data, (d) => d.amount)),
      rebate: formatDollar(sumBy(data, (d) => d.rebate)),
      payable: formatDollar(sumBy(data, (d) => d.payable)),
    };
  }

  get pageDetailsData() {
    const currUser = this.user;
    if (!currUser) {
      return [];
    }
    // const currUserTree = getDownlinesTree(currUser, currUser.downlines);
    // const directDownlines = currUserTree.downlines;

    // insert self in
    const mockCurrUser = {
      ...currUser,
      downlines: [],
    };

    const downlinesData = flatten(
      // [mockCurrUser, ...directDownlines].map((directDownline) => {
      [mockCurrUser].map((directDownline) => {
        const allPages = directDownline.pages;

        return allPages.map((page) => {
          const big = page.calculation.bigCount;
          const small = page.calculation.smallCount;
          const amount = page.calculation.rawCost;
          const rebate = (amount * directDownline.ddTicketRebate) / 100;
          const payable =
            (amount * (100 - directDownline.ddTicketRebate)) / 100;
          return {
            ...page,
            upline: directDownline.userId,
            uplineName: directDownline.username,
            account: page.userId,
            username: page.username,
            big,
            small,
            rebate,
            amount,
            payable,
            createdAt: page.createdAt,
          };
        });
      })
    )
      .filter((u) => u.amount > 0)
      .sort(
        (u1, u2) =>
          customMoment(u1.createdAt).unix() - customMoment(u2.createdAt).unix()
      )
      .sort((u1, u2) => u1.account.localeCompare(u2.account))
      .sort((u1, u2) => u1.upline.localeCompare(u2.upline));

    const rowData = downlinesData.map((row) => {
      return {
        ...row,
        uplineCount: downlinesData.filter((d) => d.upline === row.upline)
          .length,
        accountCount: downlinesData.filter((d) => d.account === row.account)
          .length,
      };
    });

    return rowData;
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY hh:mm:ss A");
  }

  async savePage(id: DdPage["id"]) {
    const result = await this.$apollo.query<{ page: DdPage }>({
      query: queries.page,
      variables: {
        id,
      },
    });
    const page = result.data.page;

    // const page = this.pageDetailsData.find(p => p.id === id);
    if (!page) {
      return;
    }
    const { isOk } = await this.$dialog.confirm({
      title: "Save bet?",
      text: "Bet page will be saved under Saved Bets",
      icon: "question",
    });

    if (!isOk) {
      return;
    }
    try {
      const result = await this.$apollo.mutate({
        mutation: mutations.saveDdEntry,
        variables: {
          page: {
            id: undefined,
            page: page.name,
            userId: page.userId,
            entries: page.entries.map((e) => ({
              number: e.number,
              day: 7,
              big: e.big,
              small: e.small,
              mode: e.mode,
            })),
            storedPageType: StoredPageType.Saved,
          },
        },
      });
      const pageId = result.data.saveDdEntry;
      this.$dialog.alert({ text: "Page saved to Saved Bets" });
      this.$router.push({
        name: "saved bet",
        query: { pageId, userId: page.userId },
      });
    } catch (err) {
      errorMutationNotification(err);
    }
    return id;
  }

  deletePage(id: DdPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showDeletePageModal = true;
  }

  async doDeletePage(id: DdPage["id"]) {
    const { isOk } = await this.$dialog.confirm({
      title: "Delete page?",
      text: "This action cannot be undone.",
      icon: "question",
    });

    if (!isOk) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: mutations.deleteDdPage,
        variables: {
          id,
          releaseDateStart: this.selectedStartReleaseDate,
          releaseDateEnd: this.selectedStartReleaseDate,
        },
        refetchQueries: [
          {
            query: queries.userPages,
            variables: {
              releaseDateStart: customMoment(this.selectedStartReleaseDate)
                .startOf("day")
                .toDate(),
              releaseDateEnd: customMoment(this.selectedStartReleaseDate)
                .startOf("day")
                .toDate(),
              behalfOf: this.$route.query.userId,
            },
          },
        ],
      });
      this.$dialog.alert({ text: "Page deleted" });
    } catch (err) {
      errorMutationNotification(err);
    }
    return id;
  }

  renamePage(id: DdPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showRenamePageModal = true;
  }

  reloadPageName() {
    this.$apollo.queries.user.refetch();
  }
  reloadAll() {
    Object.values(this.$apollo.queries).forEach((q) => q.refetch());
  }

  viewPageDetails(id: DdPage["id"]) {
    const page = this.pageDetailsData.find((p) => p.id === id);
    if (!page) {
      return;
    }
    this.selectedPage = page;
    this.showPageDetailModal = true;
  }
}
