import { Route } from "vue-router";
import { capitalize } from "lodash";
import { vueApp } from "@/main";
import { ApolloError } from "apollo-client";

export const successMutationNotification = (successMessage: string) => {
  vueApp.$dialog.alert({
    text: successMessage,
  });
};

export const errorMutationNotification = (error: ApolloError | any) => {
  if (error?.networkError?.statusCode === 400) {
    const errors = error?.networkError.result.errors;
    if (errors.length) {
      vueApp.$dialog.alert({
        // text: errors[0].message,
        text: "Invalid Input",
        escToClose: true,
      });
    }
  } else {
    vueApp.$dialog.alert({
      text: error.message.replace("GraphQL error:", ""),
    });
    // const msg = error.message.replace("GraphQL error:", "");
    // vueApp.$toast.add({
    //   severity: "error",
    //   summary: "Error",
    //   detail: msg,
    //   life: 10000,
    // });

    // vueApp.$confirm.require({
    //   header: " ",
    //   message: error.message.replace("GraphQL error:", ""),
    //   acceptClass: "w-screen",
    //   rejectClass: "invisible hidden",
    //   acceptLabel: "Ok",
    // });
  }
};

export const retrieveErrorMsg = (error: ApolloError | any) => {
  if (error?.networkError?.statusCode === 400) {
    const errors = error?.networkError.result.errors;
    if (errors.length) {
      return "Invalid input";
    }
  } else {
    return error.message.replace("GraphQL error:", "");
  }
};

export const formatRouteName = (route: Route) => {
  if (route.meta?.title) return route.meta.title;
  const words = route.name?.split("-");
  return words?.map((word) => capitalize(word)).join(" ") || "";
};
