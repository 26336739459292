import { TTEntry, TTEntryInput } from "@/types";
import { validateTTNumbers } from "./validateTTNumbers";

export const parseTtEntryInputs = (
  entries: TTEntryInput[]
): (TTEntry | null)[] => {
  const validEntries = entries.map((entryInput) => {
    if (!validateTTNumbers(entryInput, false)) {
      return null;
    }
    const unit = entryInput.unit || 0;

    if (unit === 0) {
      return null;
    }

    const mode = entryInput.mode === '' ? undefined : entryInput.mode;
    return {
      ...entryInput,
      mode,
      unit,
    };
  });

  return validEntries;
};
