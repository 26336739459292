import Vue, { provide } from "vue";
import App from "./App.vue";
import "./unregisterServiceWorker";
import router from "./router";

import "./assets/styles/tailwind.scss";
import "./assets/styles/styles.scss";
import VueApollo from "vue-apollo";
import { apolloClient } from "./apollo";

import { DefaultApolloClient } from "@vue/apollo-composable";

import { i18n } from "@/plugins/i18n";

import { pinia } from "@/store/pinia";

import VueTailwind from "vue-tailwind";
import { settings } from "./vueTailwind";

import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
  faPowerOff,
  faAngleDown,
  faBars,
  faExternalLinkAlt,
  faUser,
  faRandom,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import moment from "moment-timezone";

import PrimeVue from "primevue/config";

import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import SelectButton from "primevue/selectbutton";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import Button from "primevue/button";
import Tooltip from "primevue/tooltip";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import SplitButton from "primevue/splitbutton";
import Tree from "primevue/tree";
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";
import FileUpload from "primevue/fileupload";
import ProgressSpinner from "primevue/progressspinner";
import Paginator from "primevue/paginator";
import Dropdown from "primevue/dropdown";
import Password from "primevue/password";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

import Loader from "@/components/common/Loader.vue";
import InputGroup from "@/components/common/InputGroup.vue";
import TimeInput from "@/components/common/TimeInput.vue";
import DateSelector from "@/components/common/DateSelector.vue";
import Select from "@/components/common/Select.vue";
import Modal from "@/components/common/Modal.vue";

import "./assets/styles/primevue.scss";
import { apolloClientV2 } from "./apollo/v2";

Vue.use(PrimeVue);

Vue.component("SelectButton", SelectButton);
Vue.component("Message", Message);
Vue.component("InlineMessage", InlineMessage);
Vue.component("Password", Password);
Vue.component("InputSwitch", InputSwitch);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("Row", Row);
Vue.component("Dropdown", Dropdown);
Vue.component("Button", Button);
Vue.component("TabView", TabView);
Vue.component("Paginator", Paginator);
Vue.component("TabPanel", TabPanel);
Vue.component("Checkbox", Checkbox);
Vue.component("InputText", InputText);
Vue.component("InputNumber", InputNumber);
Vue.component("SplitButton", SplitButton);
Vue.component("Tree", Tree);
Vue.component("Tag", Tag);
Vue.component("MultiSelect", MultiSelect);
Vue.component("ProgressSpinner", ProgressSpinner);
Vue.component("FileUpload", FileUpload);
Vue.component("Textarea", Textarea);
Vue.component("Dialog", Dialog);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("Toast", Toast);
Vue.directive("tooltip", Tooltip);

Vue.component("Loader", Loader);
Vue.component("Select", Select);
Vue.component("InputGroup", InputGroup);
Vue.component("TimeInput", TimeInput);
Vue.component("DateSelector", DateSelector);
Vue.component("Modal", Modal);

library.add(faExternalLinkAlt as IconDefinition);
library.add(faPowerOff as IconDefinition);
library.add(faAngleDown as IconDefinition);
library.add(faBars as IconDefinition);
library.add(faUser as IconDefinition);
library.add(faRandom as IconDefinition);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueTailwind, settings);
Vue.use(ConfirmationService);
Vue.use(ToastService);

Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    // apollo options applied to all queries in components
    $query: {
      fetchPolicy: "cache-and-network",
      prefetch: false,
    },
  },
});

Vue.config.productionTip = false;

moment.tz.setDefault("Asia/Singapore");
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

export { moment as customMoment };

export const vueApp = new Vue({
  setup() {
    provide(DefaultApolloClient, apolloClientV2);
  },
  router,
  pinia,
  apolloProvider,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
