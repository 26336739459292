import { TTDay } from "@/types";
import { sumBy } from "lodash";
import { getTtEntriesRaw } from "../tt/getTtEntriesRaw";

export const parseTtt = (rawText: string, day: TTDay) => {
  const linesArr = rawText.split("\n");
  // sample line
  // 6+4158+   0+ 10+BK  *1  |Y

  let pageCheck = null as null | string;
  const linesCalculated = linesArr
    .filter((l) => l.length >= 47)
    .map((line, index) => {
      const sectionsArr = line.split("+");
      if (sectionsArr.length !== 3) {
        throw new Error("Not enough + detected on line " + (index + 1));
      }
      const numberSection = sectionsArr[0];
      const unitSection = sectionsArr[1];
      const otherSection = sectionsArr[2];

      const otherSectionArr = otherSection.split("*");
      if (otherSectionArr.length !== 2) {
        throw new Error("No * detected on line " + (index + 1));
      }
      // const agentSection = otherSectionArr[0];

      const pageAndModeArr = otherSectionArr[1].split("|");
      if (pageAndModeArr.length !== 2) {
        throw new Error("No | detected on line " + (index + 1));
      }
      const pageSection = pageAndModeArr[0];
      const modeSection = pageAndModeArr[1];

      // Ignore day in file
      // const dayContent = line.slice(0, 1);
      // const day = parseInt(dayContent);
      // if (isNaN(day)) {
      //   throw new Error("Issue parsing day");
      // }

      let dayMultiplier = 1;
      if (day === 5) {
        dayMultiplier = 2;
      }

      const numbersRaw = numberSection.trim().split("-");
      if (numbersRaw.length !== 10) {
        throw new Error("9 dashes - in numbers required");
      }
      const numbers = numbersRaw.filter((numString) => numString.trim().length);

      numbersRaw.forEach((num) => {
        const numberedNum = Number(num);
        if (isNaN(numberedNum)) {
          throw new Error(`${num} is not a number`);
        }
        if (numberedNum < 0) {
          throw new Error("Number must be more than 0");
        }
        if (numberedNum > 49) {
          throw new Error("Number must be less than 49");
        }
        if (numbers.filter((n) => n === num).length > 1) {
          throw new Error(`${num} is duplicated`);
        }
      });

      if (isNaN(Number(unitSection))) {
        throw new Error("unit is not a number");
      }

      const page = pageSection.trim();

      if (pageCheck === null) {
        pageCheck = page;
      } else {
        if (pageCheck !== page) {
          throw new Error("Page is different on line " + (index + 1));
        }
      }

      const modeCleaned = modeSection.trim();

      //   TODO: MODE CHECK
      const MODE_MAP = new Map(
        Object.entries({
          YY: null,
        })
      );
      const mode = MODE_MAP.get(modeCleaned);
      const unit = +unitSection;

      const entriesRaw = getTtEntriesRaw({
        number1: numbers[0],
        number2: numbers[1],
        number3: numbers[2],
        number4: numbers[3],
        number5: numbers[4],
        number6: numbers[5],
        number7: numbers[6],
        number8: numbers[7],
        number9: numbers[8],
        number10: numbers[9],
        mode: mode as any,
        unit,
      });

      const modeCount = 1;

      return {
        day,
        numbers,
        unit,
        mode,
        page,
        unitCalculated: sumBy(entriesRaw, (er) => er.unit) * dayMultiplier,
        dayMultiplier,
        count: modeCount * dayMultiplier,
      };
    });
  if (linesCalculated.length === 0) {
    throw new Error("No valid entries.");
  }
  return linesCalculated;
};
