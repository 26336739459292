var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"w-max relative min-w-[300px]"},[_c('table',{staticClass:"custom-table w-full max-w-full"},[_c('thead',[_c('th',{staticClass:"w-[1.5rem]"},[_vm._v("#")]),(_vm.showDay)?_c('th',{staticClass:"w-[6.5rem]"},[_vm._v("D")]):_vm._e(),_c('th',{staticClass:"w-[1.5rem]"}),_c('th',{staticClass:"w-[5rem]"},[_vm._v("4D")]),_c('th',{staticClass:"w-[1rem]"},[_vm._v("Big")]),_c('th',{staticClass:"w-[1rem]"},[_vm._v("Small")]),_c('th',{staticClass:"font-display w-[1rem]"},[_vm._v("R i k")])]),_c('tbody',_vm._l((_vm.entries),function(entry,$index){return _c('tr',{key:$index,class:entry.mode,on:{"keypress":(event) => {
          _vm.handleKeyPresses(event, entry);
        }}},[_c('td',{staticClass:"font-bold text-center"},[_vm._v(" "+_vm._s($index + 1 + _vm.startingIndex)+" ")]),(_vm.showDay)?_c('td',{staticClass:"font-bold text-center"},[_c('Select',{staticClass:"py-1 rounded font-bold border-gray-300 text-sm",class:[
              `day-${entry.day}`,
              entry.day !== _vm.selectedDay ? 'blink' : undefined,
            ],attrs:{"showCaret":false,"options":_vm.dayOptions},model:{value:(entry.day),callback:function ($$v) {_vm.$set(entry, "day", $$v)},expression:"entry.day"}})],1):_vm._e(),_c('td',[_c('button',{staticClass:"py-1 px-1 font-semibold bg-primary text-white rounded",on:{"click":function($event){return _vm.handleQuickBet($index)}}},[_vm._v("Q")])]),_c('td',[_c('t-input',{ref:"number",refInFor:true,staticClass:"dark-input uppercase number",attrs:{"type":"text","minlength":0,"maxlength":4},on:{"keydown":(event) => {
              _vm.handleXInput(event, entry);
              _vm.handleArrowKey(event, 'number', $index);
              _vm.handlePlusMinusKey(event, 'number', $index);
              _vm.handleEnter(event, 'number', $index);
            },"input":(val) => {
              entry.number = val.replaceAll(/[^0-9xX]/g, '');
            }},model:{value:(entry.number),callback:function ($$v) {_vm.$set(entry, "number", $$v)},expression:"entry.number"}})],1),_c('td',[_c('t-input',{ref:"big",refInFor:true,staticClass:"dark-input no-spinner big",attrs:{"type":"number"},on:{"keydown":(event) => {
              _vm.handleArrowKey(event, 'big', $index);
              _vm.handlePlusMinusKey(event, 'big', $index);
              _vm.handleEnter(event, 'big', $index);
            },"input":(val) => {
              entry.big = String(val).replaceAll(/[^0-9\.]/g, '') || '';
            },"blur":(event) => {
              _vm.handleUnfocus(event, 'big', $index);
            }},model:{value:(entry.big),callback:function ($$v) {_vm.$set(entry, "big", _vm._n($$v))},expression:"entry.big"}})],1),_c('td',[_c('t-input',{ref:"small",refInFor:true,staticClass:"dark-input no-spinner small",attrs:{"type":"number"},on:{"keydown":(event) => {
                _vm.handleArrowKey(event, 'small', $index);
                _vm.handlePlusMinusKey(event, 'small', $index);
                _vm.handleEnter(event, 'small', $index);
              },"input":(val) => {
                entry.small = String(val).replaceAll(/[^0-9]/g, '') || '';
              },"blur":(event) => {
                _vm.handleUnfocus(event, 'small', $index);
              }},model:{value:(entry.small),callback:function ($$v) {_vm.$set(entry, "small", _vm._n($$v))},expression:"entry.small"}})],1),_c('td',[_c('Select',{staticClass:"dark-input mode rounded border-gray-300",attrs:{"options":_vm.modeOptions},model:{value:(entry.mode),callback:function ($$v) {_vm.$set(entry, "mode", $$v)},expression:"entry.mode"}})],1)])}),0)]),_c('table',{staticClass:"w-full custom-footer-table"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"label"},[_vm._v("B")]),_c('td',[_vm._v(_vm._s(_vm.calculations.bigCount.toFixed(2)))]),_c('td',{staticClass:"label"},[_vm._v("S")]),_c('td',[_vm._v(_vm._s(_vm.calculations.smallCount.toFixed(0)))])]),_c('tr',[_c('td',{staticClass:"label"},[_vm._v("i B")]),_c('td',[_vm._v(_vm._s(_vm.calculations.iBigCount.toFixed(2)))]),_c('td',{staticClass:"label"},[_vm._v("i S")]),_c('td',[_vm._v(_vm._s(_vm.calculations.iSmallCount.toFixed(0)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }