export const validateTTNumber = (number: string) => {
  // ensure number is 01 to 49
  if (!number) {
    return false;
  }
  const parsedNumber = Number(number);
  if (isNaN(parsedNumber)) {
    return false;
  }
  if (parsedNumber < 1 || parsedNumber > 49) {
    return false;
  }
  if (parsedNumber >= 1 && parsedNumber < 10) {
    if (number.charAt(0) !== "0") {
      return false;
    }
  }
  return true;
};
