
import { Vue, Component, Prop } from "vue-property-decorator";
import { User, NumberStake, TtNumberStake } from "@/CodegenTypes";
import { formatDollar } from "@/helpers/formatDollar";
import { startCase, sumBy, uniq, uniqBy } from "lodash";
import { customMoment } from "@/main";
import { queries } from "@/apollo";
import { formatCount } from "@/helpers/formatCount";

@Component({
  apollo: {
    ttNumberStakes: {
      query: queries.ttNumberStakes,
      skip() {
        return !this.startReleaseDate || !this.number || !this.userId;
      },
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          releaseDate: customMoment(this.startReleaseDate)
            .startOf("day")
            .toDate(),
          appMode: "DD",
          behalfOf: this.userId,
          number: this.number
        };
      }
    }
  }
})
export default class TTNumberStakeModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) startReleaseDate!: Date;
  @Prop({ required: true }) userId!: User["userId"];
  @Prop({ required: true }) number!: string;

  ttNumberStakes = null as null | TtNumberStake[];

  formatDollar = formatDollar;
  startCase = startCase;
  formatCount = formatCount;

  get summary() {
    if (!this.ttNumberStakes) {
      return null;
    }
    return {
      numberCount: this.ttNumberStakes.length,
      in: {
        amount: sumBy(this.ttNumberStakes, s => s.in)
      },
      intake: {
        amount: sumBy(this.ttNumberStakes, s => s.intake)
      },
      toCompany: {
        amount: sumBy(this.ttNumberStakes, s => s.toCompany)
      }
    };
  }

  formatDate(date: string) {
    return customMoment(date).format("DD MMM YYYY");
  }
  formatDatetime(date: string) {
    return customMoment(date).format("DD/MMM HH:mm:ss");
  }

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }
}
