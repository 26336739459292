import { EntryInput } from "@/types";
import { countNumberX } from "./countNumberX";

export const validateNumber = (entry: EntryInput) => {
  const number = parseInt(entry.number);
  const xCount = countNumberX(entry.number);
  if (xCount) {
    const numberWithoutX = entry.number.replace(/x/gi, "");
    const totalLength = numberWithoutX.length + xCount;
    if (totalLength !== 4 || isNaN(Number(numberWithoutX))) {
      return false;
    }
  } else {
    if (
      entry.number.length < 4 ||
      isNaN(Number(entry.number)) ||
      number < 0 ||
      number > 9999
    ) {
      return false;
    }
  }
  return true;
};
