import { StoredDdPage } from "@/CodegenTypes";
import { first, sumBy } from "lodash";
import { countPermutations } from "../dd/countPermutations";
import { fixRounding } from "../fixRounding";

export const summariseStoredPage = (storedPage: StoredDdPage) => {
  const entries = storedPage.entries;

  const firstEntry = first(entries);
  if (!firstEntry) {
    throw new Error("No first entry");
  }

  const entriesCalculated = entries.map(entry => {
    const day = entry.day;
    let dayMultiplier = 1;
    if (day === 2) {
      dayMultiplier = 2;
    } else if (day === 0) {
      dayMultiplier = 3;
    }

    let modeMultiplierBig = 1;
    let modeMultiplierSmall = 1;
    const permutations = countPermutations(entry.number);
    if (entry.mode === "R") {
      modeMultiplierBig = permutations;
      modeMultiplierSmall = permutations;
    }
    if (entry.mode === "k"){
      modeMultiplierBig = 0.625;
      modeMultiplierSmall = 1.4285714286;
    }

    let modeCount = 1;
    if (entry.mode === "R" || entry.mode === "i") {
      modeCount = permutations;
    }

    return {
      ...entry,
      bigCalculated: entry.big * dayMultiplier * modeMultiplierBig,
      smallCalculated: entry.small * dayMultiplier * modeMultiplierSmall,
      count: modeCount * dayMultiplier
    };
  });
  const bigTotal = sumBy(entriesCalculated, e => e.bigCalculated);

  const smallTotal = sumBy(entriesCalculated, e => e.smallCalculated);
  const count = sumBy(entriesCalculated, e => e.count);

  const amountTotal = bigTotal * 1.6 + smallTotal * 0.7;
  return {
    day: firstEntry.day,
    bigTotal,
    smallTotal,
    count,
    amountTotal: fixRounding(amountTotal, 2)
  };
};
