export const X_LIMIT = 2;

export const FEATURE_TOGGLES = {
  showSimpleEntry: process.env.VUE_APP_SHOW_SIMPLE_ENTRY == "true",
  showMassEntryV1: process.env.VUE_APP_SHOW_MASS_ENTRY_V1 == "true",
  showMassEntryV2: process.env.VUE_APP_SHOW_MASS_ENTRY_V2 == "true",
  showSavedBet: process.env.VUE_APP_SHOW_SAVED_BET == "true",
  clickableDdReportPosition: process.env.VUE_APP_CLICKABLE_DD_REPORT_POSITION == "true",
  clickableTtReportPosition: process.env.VUE_APP_CLICKABLE_TT_REPORT_POSITION == "true",
};

// console.debug({ FEATURE_TOGGLES });
