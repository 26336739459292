import { NavigationGuard } from "vue-router";

import { AUTH_TYPES } from "@/constants";
import { useRootStore } from "@/store/pinia";

export const authGuard: NavigationGuard = async (to, from, next) => {
  if (!to.meta?.authRequirement) {
    next();
    return;
  }
  const store = useRootStore();
  const loggedIn = store.isLoggedIn;

  // check auth requirement
  if (to.meta?.authRequirement === AUTH_TYPES.REQUIRE_LOGGED_IN) {
    if (loggedIn) {
      next();
      return;
    } else {
      // go login page

      next({ name: "login", params: to.params });
      return;
    }
  } else if (to.meta?.authRequirement === AUTH_TYPES.REQUIRE_NOT_LOGGED_IN) {
    if (loggedIn) {
      // go dashboard
      next({ name: "home", params: to.params });
      return;
    } else {
      store.logout();
      next();
      return;
    }
  }
};

export const appModeGuard: NavigationGuard = async (to, from, next) => {
  if (to.meta?.appMode) {
    useRootStore().appMode = to.meta?.appMode;
  }

  next();
  return;
};
