import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { appModeGuard, authGuard } from "./navGuards";
import { AUTH_TYPES } from "@/constants";

import MainLayout from "@/components/layout/MainLayout.vue";
import { AppMode } from "@/CodegenTypes";
import { getCurrentInstance } from "vue";

import TNC from "@/components/pages/login/TNC.vue";
import Home from "@/components/pages//home/Home.vue";
import Login from "@/components/pages/login/Login.vue";
import Profile from "@/components/pages/profile/Profile.vue";
import Downlines from "@/components/pages/profile/Downlines.vue";
import Payment from "@/components/pages/profile/Payment.vue";
import PaymentCollection from "@/components/modules/profile/PaymentCollection.vue";
import PaymentHistory from "@/components/modules/profile/PaymentHistory.vue";

import LoginHistory from "@/components/pages/log/LoginHistory.vue";
import AuditLogs from "@/components/pages/log/AuditLogs.vue";

import FixedBet from "@/components/pages/entry/FixedBet.vue";
import MassEntry from "@/components/pages/entry/MassEntry.vue";
import MassEntry2 from "@/components/pages/entry/MassEntry2.vue";
import Upload from "@/components/pages/entry/Upload.vue";
import TTMassEntry from "@/components/pages/entry/TTMassEntry.vue";
import TTMassEntry2 from "@/components/pages/entry/TTMassEntry2.vue";
import TTFixedBet from "@/components/pages/entry/TTFixedBet.vue";
import TTUpload from "@/components/pages/entry/TTUpload.vue";

import BetDetails from "@/components/pages/bet-details/BetDetails.vue";
import PageDetails from "@/components/pages/bet-details/PageDetails.vue";
import UserStake from "@/components/pages/bet-details/UserStake.vue";
import DeletedBetDetails from "@/components/pages/bet-details/DeletedBetDetails.vue";
import IntakeDetails from "@/components/pages/bet-details/IntakeDetails.vue";
import TTIntakeDetails from "@/components/pages/tt-bet-details/TTIntakeDetails.vue";
import TTBetDetails from "@/components/pages/tt-bet-details/TTBetDetails.vue";
import TTPageDetails from "@/components/pages/tt-bet-details/TTPageDetails.vue";
import TTUserStake from "@/components/pages/tt-bet-details/TTUserStake.vue";
import TTDeletedBetDetails from "@/components/pages/tt-bet-details/TTDeletedBetDetails.vue";

import SummaryReport from "@/components/pages/company-report/SummaryReport.vue";
import CompanyPLReport from "@/components/pages/company-report/CompanyPLReport.vue";
import CompanyStakeReport from "@/components/pages/company-report/CompanyStakeReport.vue";
import FullReport from "@/components/pages/company-report/FullReport.vue";
import CollectionReport from "@/components/pages/company-report/CollectionReport.vue";
import StrikeReport from "@/components/pages/company-report/StrikeReport.vue";
import DdResults from "@/components/pages/company-report/DdResults.vue";
import StakeFile from "@/components/pages/bet-details/StakeFile.vue";
import TTCompanyPLReport from "@/components/pages/tt-company-report/CompanyPLReport.vue";
import TTCompanystakeReport from "@/components/pages/tt-company-report/CompanyStakeReport.vue";
import TTCollectionReport from "@/components/pages/tt-company-report/CollectionReport.vue";
import TTStrikeReport from "@/components/pages/tt-company-report/StrikeReport.vue";
import TtResults from "@/components/pages/tt-company-report/TtResults.vue";
import TTStakeFile from "@/components/pages/tt-bet-details/StakeFile.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "index",
    redirect: "login",
  },
  {
    path: "/login",
    name: "login",
    meta: {
      authRequirement: AUTH_TYPES.REQUIRE_NOT_LOGGED_IN,
    },
    component: Login,
  },
  {
    path: "/tnc",
    name: "tnc",
    meta: {
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: TNC,
  },
  {
    path: "/home",
    name: "home",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: Home,
  },
  {
    path: "/entry/savedbet",
    name: "saved bet",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: () => import("@/components/pages/entry/SavedBet.vue"),
  },
  {
    path: "/entry/fixedbet",
    name: "fixed bet",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: FixedBet,
  },
  {
    path: "/entry/mass",
    name: "mass entry",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: MassEntry,
  },
  {
    path: "/entry/mass2",
    name: "mass entry 2",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: MassEntry2,
  },
  {
    path: "/entry/simple",
    name: "simple entry",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: MassEntry2,
  },
  {
    path: "/entry/totosimple",
    name: "toto simple entry",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTMassEntry2,
  },
  {
    path: "/entry/totomass",
    name: "tt mass entry",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTMassEntry,
  },
  {
    path: "/entry/totomass2",
    name: "tt mass entry 2",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTMassEntry2,
  },
  {
    path: "/entry/totosavedbet",
    name: "tt saved bet",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: () => import("@/components/pages/entry/TTSavedBet.vue"),
  },
  {
    path: "/entry/totofixedbet",
    name: "tt fixed bet",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTFixedBet,
  },
  {
    path: "/entry/totoupload",
    name: "tt upload entry",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTUpload,
  },
  {
    path: "/entry/upload",
    name: "upload entry",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: Upload,
  },
  {
    path: "/entry/telegram",
    name: "telegram help",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: () => import("@/components/pages/entry/TelegramHelp.vue"),
  },
  {
    path: "/entry/toto-telegram",
    name: "toto telegram help",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: () => import("@/components/pages/entry/TTTelegramHelp.vue"),
  },
  {
    path: "/entry/payout-guide",
    name: "dd payout guide",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: () => import("@/components/pages/entry/DdPayoutGuide.vue"),
  },
  {
    path: "/entry/toto-payout-guide",
    name: "tt payout guide",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: () => import("@/components/pages/entry/TtPayoutGuide.vue"),
  },
  {
    path: "/report/bet-details",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: BetDetails,
    children: [
      {
        path: "",
        name: "page details",
        meta: {
          layout: MainLayout,
          authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
          appMode: AppMode.Dd,
        },
        component: PageDetails,
      },
      {
        path: "user-stake",
        name: "user stake",
        meta: {
          layout: MainLayout,
          authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
          appMode: AppMode.Dd,
        },
        component: UserStake,
      },
    ],
  },
  {
    path: "/report/toto-bet-details",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTBetDetails,
    children: [
      {
        path: "",
        name: "tt page details",
        meta: {
          layout: MainLayout,
          authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
          appMode: AppMode.Tt,
        },
        component: TTPageDetails,
      },
      {
        path: "user-stake",
        name: "tt user stake",
        meta: {
          layout: MainLayout,
          authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
          appMode: AppMode.Tt,
        },
        component: TTUserStake,
      },
    ],
  },
  {
    path: "/report/deleted-bet-details",
    name: "deleted bet details",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: DeletedBetDetails,
  },
  {
    path: "/report/toto-deleted-bet-details",
    name: "toto deleted bet details",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTDeletedBetDetails,
  },
  {
    path: "/report/intake-details",
    name: "intake details",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: IntakeDetails,
  },
  {
    path: "/report/toto-intake-details",
    name: "toto intake details",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTIntakeDetails,
  },
  {
    path: "/report/companypl",
    name: "companypl report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: CompanyPLReport,
  },
  {
    path: "/report/toto-companypl",
    name: "toto companypl report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTCompanyPLReport,
  },
  {
    path: "/report/stakereport",
    name: "stake report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: CompanyStakeReport,
  },
  {
    path: "/report/toto-stakereport",
    name: "toto stake report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTCompanystakeReport,
  },
  {
    path: "/report/fullreport",
    name: "full report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: FullReport,
  },
  {
    path: "/report/toto-fullreport",
    name: "toto full report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: () =>
      import("@/components/pages/tt-company-report/FullReport.vue"),
  },
  {
    path: "/report/collectionreport",
    name: "collection report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: CollectionReport,
  },
  {
    path: "/report/totocollectionreport",
    name: "toto collection report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTCollectionReport,
  },
  {
    path: "/report/strikereport",
    name: "strike report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: StrikeReport,
  },
  {
    path: "/report/toto-strikereport",
    name: "toto strike report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTStrikeReport,
  },
  {
    path: "/report/4d-results",
    name: "4d results",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: DdResults,
  },
  {
    path: "/report/toto-results",
    name: "toto results",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TtResults,
  },
  {
    path: "/report/summary-report",
    name: "summary report",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      // appMode: AppMode.Dd
    },
    component: SummaryReport,
  },
  {
    path: "/report/stakefile",
    name: "stake file",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: StakeFile,
  },
  {
    path: "/report/toto-stakefile",
    name: "toto stake file",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: TTStakeFile,
  },
  {
    path: "/admin/settings",
    name: "admin-settings",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: () => import("@/components/pages/admin/Settings.vue"),
  },
  {
    path: "/admin/outset",
    name: "admin-outset",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: () => import("@/components/pages/admin/Outset.vue"),
  },
  {
    path: "/admin/toto-outset",
    name: "toto-admin-outset",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: () => import("@/components/pages/admin/TtOutset.vue"),
  },
  {
    path: "/admin/control-panel",
    name: "admin-controlpanel",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Dd,
    },
    component: () => import("@/components/pages/admin/ControlPanel.vue"),
  },
  {
    path: "/admin/toto-control-panel",
    name: "admin-toto-controlpanel",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
      appMode: AppMode.Tt,
    },
    component: () => import("@/components/pages/admin/TtControlPanel.vue"),
  },
  {
    path: "/profile/profile",
    name: "profile-profile",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: Profile,
  },
  {
    path: "/profile/downlines",
    name: "profile-downlines",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: Downlines,
  },
  {
    path: "/profile/payment",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: Payment,
    children: [
      {
        path: "",
        name: "profile-payment",
        meta: {
          layout: MainLayout,
          authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
        },
        component: PaymentCollection,
      },
      {
        path: "history",
        name: "profile-payment-history",
        meta: {
          layout: MainLayout,
          authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
        },
        component: PaymentHistory,
      },
    ],
  },
  {
    path: "/log/loginhistory",
    name: "log-loginhistory",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: LoginHistory,
  },
  {
    path: "/log/audit",
    name: "log-auditlog",
    meta: {
      layout: MainLayout,
      authRequirement: AUTH_TYPES.REQUIRE_LOGGED_IN,
    },
    component: AuditLogs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authGuard);
router.beforeEach(appModeGuard);

// utils.js

export function useRoute() {
  const vueInstance = getCurrentInstance();
  if (!vueInstance) {
    throw new Error("No vue instance found");
  }
  const route = vueInstance.proxy.$route;
  return route;
}

export default router;
