
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { TtPage } from "@/CodegenTypes";
import { times } from "lodash";

import { TT_MODE_NAMES } from "@/constants/index";
import { mutations, queries } from "@/apollo";
import { formatCount } from "@/helpers/formatCount";
import { formatDollar } from "@/helpers/formatDollar";
import { customMoment } from "@/main";
import { errorMutationNotification } from "@/utils";

@Component({
  apollo: {
    ttPage: {
      query: queries.ttPage,
      skip() {
        return !this.pageId;
      },
      variables() {
        return {
          id: this.pageId,
          releaseDates: this.releaseDates,
        };
      },
    },
  },
  components: {},
})
export default class TTPageDetailsModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) pageId?: TtPage["id"];
  @Prop({ required: false }) releaseDates?: Date[];
  @Prop({ default: false }) showDelete?: boolean;

  ttPage = null as null | TtPage;
  checkedEntries = [] as TtPage["id"][];
  formatDollar = formatDollar;
  formatCount = formatCount;

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY hh:mm:ssA");
  }

  startIndex = 0;
  PER_PAGE = 40;

  clickCounter = 0;


  get rowsPerTable() {
    return this.PER_PAGE / 2;
  }

  get totalEntriesCount() {
    return this.ttPage?.entries.length;
  }

  get entriesInPage() {
    const endIndex = this.startIndex + this.PER_PAGE;
    return this.ttPage?.entries.slice(this.startIndex, endIndex);
  }

  get entriesInFirstTable() {
    if (!this.entriesInPage) {
      return [];
    }
    const dataRows = this.entriesInPage.slice(0, this.rowsPerTable);
    const rowsToPad = this.rowsPerTable - dataRows.length - 1;
    const padRows = [] as any[];
    times(rowsToPad, () => {
      padRows.push({ number: "", unit: "", mode: "", amount: "" });
    });
    return [...dataRows, ...padRows];
  }
  get entriesInSecondTable() {
    if (!this.entriesInPage) {
      return [];
    }
    const dataRows = this.entriesInPage.slice(this.rowsPerTable);
    const rowsToPad = this.rowsPerTable - dataRows.length - 1;
    const padRows = [] as any[];
    times(rowsToPad, () => {
      padRows.push({ number: "", unit: "", mode: "", amount: "" });
    });
    return [...dataRows, ...padRows];
  }

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }

  convertModeValToName(val: string) {
    return TT_MODE_NAMES[val];
  }

  async handleDelete() {
    const { isOk } = await this.$dialog.confirm({
      title: "Delete entry?",
      text: "This action cannot be undone.",
      icon: "question",
    });
    if (!isOk) {
      return;
    }
    try {
      await this.$apollo.mutate({
        mutation: mutations.deleteTtPageEntries,
        variables: {
          id: this.pageId,
          releaseDates: this.releaseDates,
          entryIds: this.checkedEntries
        },
      });
      this.$apollo.queries.ttPage.refetch();
      this.$dialog.alert({ text: "Entries deleted" });
      this.checkedEntries = [];
      this.$emit("submit");
    } catch (err) {
      errorMutationNotification(err);
    }
  }

  @Watch("value")
  reset() {
    this.startIndex = 0;
  }
}
