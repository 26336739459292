
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DdPage } from "@/CodegenTypes";
import { mutations } from "@/apollo";
import { clone } from "lodash";
import { errorMutationNotification } from "@/utils";

@Component({
  apollo: {},
  components: {}
})
export default class RenamePageModal extends Vue {
  @Prop({ required: true }) page!: DdPage;
  @Prop({ required: true, default: false }) value!: boolean;
  selectedAppModeIndex = 0;
  form = this.getDefaultForm();

  mounted() {
    this.form = this.getDefaultForm();
  }

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }

  getDefaultForm() {
    return clone({
      name: this.page.name
    });
  }

  @Watch("page")
  resetForm() {
    this.form = this.getDefaultForm();
  }

  async handleSubmit() {
    try {
      const result = await this.$apollo.mutate({
        mutation: mutations.renameDdPage,
        variables: {
          id: this.page.id,
          newName: this.form.name
        }
      });
      this.$emit("submit");
      this.toggleModal(false);
    } catch (err) {
      errorMutationNotification(err);
    }
  }
}
