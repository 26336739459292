import { Day } from "@/types";
import { countPermutations } from "../dd/countPermutations";

export const parseAra = (rawText: string, day: Day) => {
  const linesArr = rawText.split("\n");
  // sample line
  // 6+4158+   0+ 10+BK  *1  |Y

  let pageCheck = null as null | string;
  const linesCalculated = linesArr
    .filter((l) => l.length >= 26)
    .map((line, index) => {
      const sectionsArr = line.split("+");
      if (sectionsArr.length !== 5) {
        throw new Error("Not enough + detected on line " + (index + 1));
      }
      // const daySection = sectionsArr[0]; // IGNORE
      const numberSection = sectionsArr[1];
      const bigSection = sectionsArr[2];
      const smallSection = sectionsArr[3];
      const otherSection = sectionsArr[4];

      const otherSectionArr = otherSection.split("*");
      if (otherSectionArr.length !== 2) {
        throw new Error("No * detected on line " + (index + 1));
      }
      // const agentSection = otherSectionArr[0];

      const pageAndModeArr = otherSectionArr[1].split("|");
      if (pageAndModeArr.length !== 2) {
        throw new Error("No | detected on line " + (index + 1));
      }
      const pageSection = pageAndModeArr[0];
      const modeSection = pageAndModeArr[1];

      // Ignore day in file
      // const dayContent = line.slice(0, 1);
      // const day = parseInt(dayContent);
      // if (isNaN(day)) {
      //   throw new Error("Issue parsing day");
      // }

      let dayMultiplier = 1;
      if (day === 2) {
        dayMultiplier = 2;
      } else if (day === 0) {
        dayMultiplier = 3;
      }

      const number = numberSection.trim();
      if (number.length !== 4) {
        throw new Error("number must be length 4");
      }
      if (isNaN(Number(number))) {
        throw new Error("number is not a number");
      }

      if (isNaN(Number(bigSection))) {
        throw new Error("big is not a number");
      }
      if (isNaN(Number(smallSection))) {
        throw new Error("small is not a number");
      }

      const page = pageSection.trim();

      if (pageCheck === null) {
        pageCheck = page;
      } else {
        if (pageCheck !== page) {
          throw new Error("Page is different on line " + (index + 1));
        }
      }

      const modeCleaned = modeSection.trim();

      const MODE_MAP = new Map(
        Object.entries({
          Y: null,
          R: "R",
          r: "R",
          I: "i",
          i: "i",
          k: "k",
          K: "k",
        })
      );
      const mode = MODE_MAP.get(modeCleaned);

      let modeMultiplierBig = 1;
      let modeMultiplierSmall = 1;
      const permutations = countPermutations(number);
      if (mode === "R") {
        modeMultiplierBig = permutations;
        modeMultiplierSmall = permutations;
      }
      if (mode === "k") {
        modeMultiplierBig = 0.625;
        modeMultiplierSmall = 1.4285714286;
      }

      let modeCount = 1;
      if (mode === "R" || mode === "i") {
        modeCount = permutations;
      }

      const big = +bigSection;
      const small = +smallSection;

      return {
        day,
        number,
        big,
        small,
        mode,
        page,
        bigCalculated: big * dayMultiplier * modeMultiplierBig,
        smallCalculated: small * dayMultiplier * modeMultiplierSmall,
        dayMultiplier,
        count: modeCount * dayMultiplier,
      };
    });
  if (linesCalculated.length === 0) {
    throw new Error("No valid entries.");
  }
  return linesCalculated;
};
