import gql from "graphql-tag";
export default {
  login: gql`
    mutation ($userId: NonEmptyString!, $password: NonEmptyString!) {
      login(userId: $userId, password: $password) {
        refreshToken
        accessToken
      }
    }
  `,
  createUser: gql`
    mutation ($payload: CreateUserPayload!) {
      createUser(payload: $payload)
    }
  `,
  updateUser: gql`
    mutation ($payload: UpdateUserPayload!) {
      updateUser(payload: $payload)
    }
  `,
  updateUserCredit: gql`
    mutation ($payload: UpdateUserCreditPayload!) {
      updateUserCredit(payload: $payload)
    }
  `,

  updateScrollingText: gql`
    mutation updateScrollingText($appMode: AppMode!, $value: String!) {
      updateScrollingText(appMode: $appMode, value: $value)
    }
  `,
  updateTelegramBotName: gql`
    mutation updateTelegramBotName($appMode: AppMode!, $value: String!) {
      updateTelegramBotName(appMode: $appMode, value: $value)
    }
  `,
  updateTnc: gql`
    mutation updateTnc($value: String!) {
      updateTnc(value: $value)
    }
  `,
  deleteAnnouncement: gql`
    mutation deleteAnnouncement($id: NonNegativeInt!) {
      deleteAnnouncement(id: $id) {
        id
      }
    }
  `,
  upsertAnnouncement: gql`
    mutation upsertAnnouncement($announcement: AnnouncementPayload!) {
      upsertAnnouncement(announcement: $announcement) {
        id
        createdAt
        mode
        text
      }
    }
  `,
  updateWindowSetting: gql`
    mutation updateWindowSetting(
      $appMode: AppMode!
      $payload: WindowSettingPayload!
    ) {
      updateWindowSetting(appMode: $appMode, payload: $payload)
    }
  `,
  insertDdEntry: gql`
    mutation insertDdEntry($page: DDPagePayload!) {
      insertDdEntry(page: $page)
    }
  `,
  insertTtEntry: gql`
    mutation insertTtEntry($page: TTPagePayload!) {
      insertTtEntry(page: $page)
    }
  `,
  saveDdEntry: gql`
    mutation saveDdEntry($page: SaveDDPagePayload!) {
      saveDdEntry(page: $page)
    }
  `,
  saveTtEntry: gql`
    mutation saveTtEntry($page: SaveTTPagePayload!) {
      saveTtEntry(page: $page)
    }
  `,
  deleteSavedDdPage: gql`
    mutation deleteSavedDdPage($id: UUID!) {
      deleteSavedDdPage(id: $id)
    }
  `,
  deleteSavedTtPage: gql`
    mutation deleteSavedTtPage($id: UUID!) {
      deleteSavedTtPage(id: $id)
    }
  `,
  updateGameSetting: gql`
    mutation updateGameSetting($payload: GameSettingPayload!) {
      updateGameSetting(payload: $payload)
    }
  `,
  updateTtGameSetting: gql`
    mutation updateTtGameSetting($payload: TTGameSettingPayload!) {
      updateTtGameSetting(payload: $payload)
    }
  `,
  updateMobileSetting: gql`
    mutation updateMobileSetting($payload: MobileSettingPayload!) {
      updateMobileSetting(payload: $payload)
    }
  `,
  updateNickname: gql`
    mutation updateNickname($nickname: NonEmptyString!) {
      updateNickname(nickname: $nickname)
    }
  `,
  updateOwnIntake: gql`
    mutation updateOwnIntake($payload: IntakeSettingPayload!) {
      updateOwnIntake(payload: $payload)
    }
  `,
  updateOwnTtIntake: gql`
    mutation updateOwnTtIntake($payload: TtIntakeSettingPayload!) {
      updateOwnTtIntake(payload: $payload)
    }
  `,
  changePassword: gql`
    mutation changePassword(
      $behalfOf: NonEmptyString!
      # $oldPassword: NonEmptyString!
      $newPassword: NonEmptyString!
    ) {
      changePassword(
        behalfOf: $behalfOf
        # oldPassword: $oldPassword
        newPassword: $newPassword
      )
    }
  `,
  copySetting: gql`
    mutation copySetting($payload: CopySettingPayload!) {
      copySetting(payload: $payload)
    }
  `,
  deleteDdPage: gql`
    mutation deleteDdPage($id: UUID!, $releaseDates: [Date!]!) {
      deleteDdPage(id: $id, releaseDates: $releaseDates)
    }
  `,
  deleteDdPageEntries: gql`
    mutation deleteDdPageEntries(
      $id: UUID!
      $releaseDates: [Date!]!
      $entryIds: [UUID!]!
    ) {
      deleteDdPageEntries(
        id: $id
        releaseDates: $releaseDates
        entryIds: $entryIds
      )
    }
  `,
  deleteTtPageEntries: gql`
    mutation deleteTtPageEntries(
      $id: UUID!
      $releaseDates: [Date!]!
      $entryIds: [UUID!]!
    ) {
      deleteTtPageEntries(
        id: $id
        releaseDates: $releaseDates
        entryIds: $entryIds
      )
    }
  `,
  deleteTtPage: gql`
    mutation deleteTtPage($id: String!, $releaseDates: [Date!]!) {
      deleteTtPage(id: $id, releaseDates: $releaseDates)
    }
  `,
  renameDdPage: gql`
    mutation renameDdPage($id: String!, $newName: String!) {
      renameDdPage(id: $id, newName: $newName)
    }
  `,
  renameTtPage: gql`
    mutation renameTtPage($id: String!, $newName: String!) {
      renameTtPage(id: $id, newName: $newName)
    }
  `,
  fileUpload: gql`
    mutation fileUpload($file: Upload!) {
      fileUpload(file: $file) {
        filename
      }
    }
  `,
  updateCompanyIntake: gql`
    mutation updateCompanyIntake(
      $releaseDate: Date!
      $big: NonNegativeInt!
      $small: NonNegativeInt!
    ) {
      updateCompanyIntake(releaseDate: $releaseDate, big: $big, small: $small)
    }
  `,
  updateTtCompanyIntake: gql`
    mutation updateTtCompanyIntake(
      $releaseDate: Date!
      $companyIntakeD1: NonNegativeInt!
      $companyIntakeD2: NonNegativeInt!
      $companyIntakeD3: NonNegativeInt!
      $companyIntakeD4: NonNegativeInt!
    ) {
      updateTtCompanyIntake(
        releaseDate: $releaseDate
        companyIntakeD1: $companyIntakeD1
        companyIntakeD2: $companyIntakeD2
        companyIntakeD3: $companyIntakeD3
        companyIntakeD4: $companyIntakeD4
      )
    }
  `,
  createExternalCompany: gql`
    mutation createExternalCompany($payload: CreateExternalCompanyPayload!) {
      createExternalCompany(payload: $payload) {
        id
      }
    }
  `,
  updateExternalCompany: gql`
    mutation updateExternalCompany($payload: UpdateExternalCompanyPayload!) {
      updateExternalCompany(payload: $payload) {
        id
      }
    }
  `,
  deleteExternalCompany: gql`
    mutation deleteExternalCompany($id: UUID!) {
      deleteExternalCompany(id: $id) {
        id
      }
    }
  `,
  createOutset: gql`
    mutation createOutset($releaseDate: Date!, $payload: OutsetPayload!) {
      createOutset(releaseDate: $releaseDate, payload: $payload)
    }
  `,
  createTtOutset: gql`
    mutation createTtOutset($releaseDate: Date!, $payload: TtOutsetPayload!) {
      createTtOutset(releaseDate: $releaseDate, payload: $payload)
    }
  `,
  deleteOutsets: gql`
    mutation deleteOutsets($ids: [UUID!]!) {
      deleteOutsets(ids: $ids)
    }
  `,
  setOutsetStatus: gql`
    mutation setOutsetStatus($ids: [UUID!]!, $status: OutsetStatus!) {
      setOutsetStatus(ids: $ids, status: $status)
    }
  `,
  deleteTtOutsets: gql`
    mutation deleteTtOutsets($ids: [UUID!]!) {
      deleteTtOutsets(ids: $ids)
    }
  `,
  setTtOutsetStatus: gql`
    mutation setTtOutsetStatus($ids: [UUID!]!, $status: OutsetStatus!) {
      setTtOutsetStatus(ids: $ids, status: $status)
    }
  `,
  upsertDdResults: gql`
    mutation upsertDdResults($releaseDate: Date!, $payload: DdResultsPayload!) {
      upsertDdResults(releaseDate: $releaseDate, payload: $payload) {
        consolation1
        consolation2
        consolation3
        consolation4
        consolation5
        consolation6
        consolation7
        consolation8
        consolation9
        consolation10
        starter1
        starter2
        starter3
        starter4
        starter5
        starter6
        starter7
        starter8
        starter9
        starter10
        firstPrize
        secondPrize
        thirdPrize
      }
    }
  `,
  syncDdResults: gql`
    mutation syncDdResults($releaseDate: Date!) {
      syncDdResults(releaseDate: $releaseDate)
    }
  `,
  syncTtResults: gql`
    mutation syncTtResults($releaseDate: Date!) {
      syncTtResults(releaseDate: $releaseDate)
    }
  `,
  upsertTtResults: gql`
    mutation upsertTtResults($releaseDate: Date!, $payload: TtResultsPayload!) {
      upsertTtResults(releaseDate: $releaseDate, payload: $payload) {
        winningNumber1
        winningNumber2
        winningNumber3
        winningNumber4
        winningNumber5
        winningNumber6
        additionalNumber
      }
    }
  `,
  generateReports: gql`
    mutation generateReports($releaseDate: Date!) {
      generateReports(releaseDate: $releaseDate)
    }
  `,
  doOutsetLinking: gql`
    mutation doOutsetLinking($releaseDate: Date!) {
      doOutsetLinking(releaseDate: $releaseDate)
    }
  `,
  linkEntry: gql`
    mutation linkEntry($releaseDate: Date!) {
      linkEntry(releaseDate: $releaseDate)
    }
  `,
  linkFetch: gql`
    mutation linkFetch($releaseDate: Date!) {
      linkFetch(releaseDate: $releaseDate)
    }
  `,
  doTtOutsetLinking: gql`
    mutation doTtOutsetLinking($releaseDate: Date!) {
      doTtOutsetLinking(releaseDate: $releaseDate)
    }
  `,
  generateTtReports: gql`
    mutation generateTtReports($releaseDate: Date!) {
      generateTtReports(releaseDate: $releaseDate)
    }
  `,
  clearReports: gql`
    mutation clearReports($releaseDate: Date!) {
      clearReports(releaseDate: $releaseDate)
    }
  `,
  clearTtReports: gql`
    mutation clearTtReports($releaseDate: Date!) {
      clearTtReports(releaseDate: $releaseDate)
    }
  `,
  updateExpenses: gql`
    mutation updateExpenses(
      $releaseDate: Date!
      $fixedExpenses: NonNegativeFloat!
      $variableExpenses: NonNegativeFloat!
    ) {
      updateExpenses(
        releaseDate: $releaseDate
        fixedExpenses: $fixedExpenses
        variableExpenses: $variableExpenses
      )
    }
  `,
  updateTtMaximumStake: gql`
    mutation updateTtMaximumStake(
      $releaseDate: Date!
      $ttMaximumStake: NonNegativeInt!
    ) {
      updateTtMaximumStake(
        releaseDate: $releaseDate
        ttMaximumStake: $ttMaximumStake
      )
    }
  `,
  updateOutsetClaim: gql`
    mutation updateOutsetClaim(
      $releaseDate: Date!
      $outsetRows: [OutsetClaimPayload!]!
    ) {
      updateOutsetClaim(releaseDate: $releaseDate, outsetRows: $outsetRows)
    }
  `,
  updateBinBreak: gql`
    mutation updateBinBreak(
      $releaseDate: Date!
      $autoCalculateBinBreak: Boolean!
      $company: CompanyBinBreakPayload!
      $outsetRows: [OutsetBinBreakPayload!]!
    ) {
      updateBinBreak(
        releaseDate: $releaseDate
        autoCalculateBinBreak: $autoCalculateBinBreak
        company: $company
        outsetRows: $outsetRows
      )
    }
  `,
  updateTtOutsetClaim: gql`
    mutation updateTtOutsetClaim(
      $releaseDate: Date!
      $outsetRows: [TtOutsetClaimPayload!]!
    ) {
      updateTtOutsetClaim(releaseDate: $releaseDate, outsetRows: $outsetRows)
    }
  `,
  updateReleaseLock: gql`
    mutation updateReleaseLock($releaseDate: Date!, $flag: Boolean!) {
      updateReleaseLock(releaseDate: $releaseDate, flag: $flag)
    }
  `,
  updatePayment: gql`
    mutation updatePayment($payload: PaymentPayload!) {
      updatePayment(payload: $payload)
    }
  `,
  deletePayment: gql`
    mutation deletePayment($id: UUID!) {
      deletePayment(id: $id)
    }
  `,
  updateReleaseDay: gql`
    mutation updateReleaseDay($releaseDate: Date!, $newReleaseDate: Date!) {
      updateReleaseDay(
        releaseDate: $releaseDate
        newReleaseDate: $newReleaseDate
      )
    }
  `,
};
