var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Loader',{attrs:{"value":_vm.$apollo.loading || _vm.generatingPdf}},[_c('main',{staticClass:"p-4 flex-1 space-y-4 overflow-y-auto max-w-3xl bg-white border-1 rounded"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("Stake File"))+" ")]),_c('section',{staticClass:"flex items-center gap-2"},[_c('DateSelector',{staticClass:"flex-2 w-full",attrs:{"weeksBackCount":1,"filterType":"not-over-or-today","ranged":false}}),_c('t-input',{staticClass:"max-w-[120px] no-spinner",attrs:{"type":"number","placeholder":_vm.$t('Find number'),"min":0,"max":9999},model:{value:(_vm.numberFilter),callback:function ($$v) {_vm.numberFilter=$$v},expression:"numberFilter"}})],1),(_vm.selectedStartReleaseDate)?_c('section',{staticClass:"flex gap-1 justify-between flex-wrap items-center"},[_c('div',{staticClass:"flex gap-3"}),(_vm.selectedBetType)?_c('SplitButton',{attrs:{"label":`${_vm.$t('Download')} [${_vm.$t(_vm.selectedBetType.name)}]`,"icon":"pi pi-plus","model":[
          {
            label: 'PDF',
            icon: 'pi pi-pdf',
            command: _vm.exportPdf,
          },
          {
            label: 'Excel Csv',
            icon: 'pi pi-excel',
            command: _vm.exportCsv,
          },
        ]},on:{"click":_vm.exportPdf}}):_vm._e()],1):_vm._e(),_c('section',{staticClass:"flex gap-3 justify-between flex-wrap"},[(_vm.ttStakeFile)?_c('div',{staticClass:"flex flex-col flex-wrap gap-2"},[(_vm.stakeTotal)?_c('table',{staticClass:"table-fixed w-full border-1 border-gray-400"},[_c('thead',[_c('th',{staticClass:"w-[25%]"},[_vm._v(_vm._s(_vm.$t("Stake")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Number Count")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Unit")))])]),_c('tbody',{staticClass:"text-center"},_vm._l((_vm.betTypes),function(betType){return _c('tr',{key:betType.id,staticClass:"cursor-pointer hover:bg-primary-800 hover:text-white",class:{
                'bg-primary-800 text-white': _vm.selectedBetTypeId === betType.id,
              },on:{"click":function($event){_vm.selectedBetTypeId = betType.id}}},[_c('td',[_vm._v(" "+_vm._s(_vm.$t(betType.name))+" ")]),(_vm.stakeTotal)?_c('td',[_vm._v(" "+_vm._s(_vm.stakeTotal[betType.id].count)+" ")]):_vm._e(),(_vm.stakeTotal)?_c('td',[_vm._v(" "+_vm._s(_vm.formatCount(_vm.stakeTotal[betType.id].unit))+" ")]):_vm._e()])}),0)]):_vm._e(),_c('p',{staticClass:"text-primary-600 font-medium pl-7"},[_vm._v(" ↑ "+_vm._s(_vm.$t("Click to change selected stake"))+" ")])]):_vm._e(),_c('div',{staticClass:"min-w-[100px] overflow-y-scroll h-[500px]",attrs:{"id":"scrollArea"}},[_c('table',{staticClass:"w-full table-fixed report-table"},[_c('thead',{staticClass:"bg-black text-white sticky top-0"},[_c('th',{staticClass:"text-center"},[_c('div',{staticClass:"flex gap-3 items-center justify-center"},[_c('i',{staticClass:"pi pi-sort-up cursor-pointer",on:{"click":function($event){_vm.sortMode = 'number-asc'}}}),_c('span',[_vm._v(_vm._s(_vm.$t("Number")))]),_c('i',{staticClass:"pi pi-sort-down cursor-pointer",on:{"click":function($event){_vm.sortMode = 'number-desc'}}})])]),_c('th',{staticClass:"text-center"},[_c('div',{staticClass:"flex gap-3 items-center justify-center"},[_c('i',{staticClass:"pi pi-sort-up cursor-pointer",on:{"click":function($event){_vm.sortMode = 'unit-asc'}}}),_c('span',[_vm._v(_vm._s(_vm.$t("Unit")))]),_c('i',{staticClass:"pi pi-sort-down cursor-pointer",on:{"click":function($event){_vm.sortMode = 'unit-desc'}}})])])]),_c('tbody',{attrs:{"id":"contentArea"}}),_c('tfoot',{staticClass:"sticky bottom-0"},[(_vm.selectedStake && _vm.stakeTotal)?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("Total"))+" ("+_vm._s(_vm.stakeTotal[_vm.selectedBetTypeId].count)+") ")]),_c('td',[_vm._v(_vm._s(_vm.formatCount(_vm.stakeTotal[_vm.selectedBetTypeId].unit)))])]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }