
import { Vue, Component } from "vue-property-decorator";
import TTMassEntryTable from "@/components/modules/entry/TTMassEntryTable.vue";
import TTEditRowsModal, {
  TTEditForm,
} from "@/components/modules/entry/TTEditRowsModal.vue";
import AccountSelectModal from "@/components/modules/account/AccountSelectModal.vue";
import { queries, mutations } from "@/apollo";
import { Release, TtPage, User } from "@/CodegenTypes";
import { TTEntryInput, TTDay } from "@/types";
import { parseTtEntryInputs } from "@/helpers/tt/parseTtEntryInputs";

import { clamp, compact } from "lodash";
import { formatDollar } from "@/helpers/formatDollar";
import { errorMutationNotification } from "@/utils";
import { customMoment } from "@/main";
import { calculateTtEntries } from "@/helpers/tt/calculateTtEntries";
import { validateTTNumbers } from "@/helpers/tt/validateTTNumbers";
import { getTtValidDayOptions } from "@/helpers/getTtValidDayOptions";
import TTPageDetailsModal from "../../modules/bet-details/TTPageDetailsModal.vue";
import { useRootStore } from "@/store/pinia";
import TTCopyBetModal from "@/components/modules/entry/TTCopyBetModal.vue";
import TTViewBetModal from "../../modules/entry/TTViewBetModal.vue";

@Component({
  components: {
    TTMassEntryTable,
    TTEditRowsModal,
    AccountSelectModal,
    TTPageDetailsModal,
    TTCopyBetModal,
    TTViewBetModal,
  },
  apollo: {
    releases: {
      query: queries.releases,
      variables() {
        return {
          appMode: useRootStore().appMode,
          weeksBackCount: 0,
        };
      },
    },

    user: {
      query: queries.userWithCreditSummary,
      skip() {
        return !this.selectedAccountId;
      },
      variables() {
        return {
          userId: this.selectedAccountId,
        };
      },
    },
  },
})
export default class TTMassEntry extends Vue {
  releases = null as null | Release[];
  selectedDayIndex = 0;
  page = "";
  user = null as null | User;
  showEditModal = false;

  isSubmitting = false;
  showAccountSelectionModal = false;
  successPageId = null;
  showPageDetailsModal = false;
  showCopyBetModal = false;
  showViewBetModal = false;

  editForm = {
    rowsFrom: undefined,
    rowsTo: undefined,
    unit: undefined,
    mode: undefined,
  } as TTEditForm;

  formatDollar = formatDollar;

  MAX_ROWS = 40;

  tables = [{ startingIndex: 0 }, { startingIndex: 20 }];

  entries = [] as TTEntryInput[];

  get userId() {
    return useRootStore().account?.userId;
  }

  get selectedAccountId() {
    return this.$route.query.userId;
  }

  get selectedAccount() {
    return this.user;
  }

  get creditForDisplay() {
    return {
      creditGiven: this.selectedAccount?.creditGiven || 0,
      creditUsed: this.selectedAccount?.creditSummary.creditUsed || 0,
      creditBalance: this.selectedAccount?.creditSummary.creditBalance || 0,
    };
  }

  get selectedDay() {
    if (this.releases) {
      return this.dayOptions[this.selectedDayIndex].value as TTDay;
    } else {
      return 1;
    }
  }

  set selectedDay(val) {
    const index = this.dayOptions.findIndex(
      (opt) => opt.value === val
    ) as TTDay;

    this.selectedDayIndex = index;
  }

  get upcomingReleases() {
    return this.releases?.filter((release) => release.windowIsOpen);
  }

  async mounted() {
    const result = await this.$apollo.query<{ releases: Release[] }>({
      query: queries.releases,
      variables: {
        appMode: useRootStore().appMode,
      },
    });
    this.releases = result.data.releases;
    const priorityIndex = this.dayOptions.findIndex(
      (opt) => opt.defaultPriority
    );
    Vue.set(this, "selectedDayIndex", priorityIndex === -1 ? 0 : priorityIndex);
    for (let i = 0; i < this.MAX_ROWS; i++) {
      this.entries.push({
        day: this.selectedDay,
        number1: "",
        number2: "",
        number3: "",
        number4: "",
        number5: "",
        number6: "",
        number7: "",
        number8: "",
        number9: "",
        number10: "",
        unit: undefined,
        mode: undefined,
      });
    }
  }

  get validEntries() {
    return compact(parseTtEntryInputs(this.entries));
  }

  get calculations() {
    return calculateTtEntries(compact(this.validEntries));
  }

  get validDayOptions() {
    if (!this.releases) {
      return [];
    }
    const validOptions = getTtValidDayOptions(this.releases);

    return validOptions;
  }

  get dayOptions() {
    const OPTIONS = [
      { label: "1=<Mon>", value: 1, defaultPriority: false },
      { label: "4=<Thu/Fri>", value: 4, defaultPriority: false },
      { label: "5=<Mon,Thu/Fri>", value: 5, defaultPriority: false },
    ];
    return OPTIONS.filter((o) => this.validDayOptions.includes(o.value));
  }

  // get totalCost() {
  //   const costs = this.validEntries.map(entry => {
  //     return calculateEntryCost(entry);
  //   });
  //   const bigCosts = costs.reduce((sum, cost) => {
  //     sum += cost.big;
  //     return sum;
  //   }, 0);
  //   const smallCosts = costs.reduce((sum, cost) => {
  //     sum += cost.small;
  //     return sum;
  //   }, 0);

  //   return {
  //     big: bigCosts,
  //     small: smallCosts
  //   };
  // }

  handleCopyPage(page: TtPage) {
    this.doClearEntries();
    const newEntries = [...this.entries];
    for (const [index, entry] of page.entries.entries()) {
      const numbers = entry.numbersText.split("-");
      newEntries[index] = {
        number1: numbers[0] || "",
        number2: numbers[1] || "",
        number3: numbers[2] || "",
        number4: numbers[3] || "",
        number5: numbers[4] || "",
        number6: numbers[5] || "",
        number7: numbers[6] || "",
        number8: numbers[7] || "",
        number9: numbers[8] || "",
        number10: numbers[9] || "",
        day: this.selectedDay,
        unit: entry.unit,
        mode: entry.mode ? entry.mode : undefined,
      };
    }
    this.entries = newEntries;
    this.page = page.name;
    return;
  }

  toggleEditModal() {
    this.showEditModal = !this.showEditModal;
  }

  getDayFromDate(dateStr: string) {
    const day = customMoment(dateStr).isoWeekday();
    return day;
  }

  changeSelectedDay(newVal: TTDay) {
    for (const entry of this.entries) {
      entry.day = newVal;
    }
  }

  focus(field: "number" | "big" | "small", inputTargetIndex: number) {
    let targetIndex = inputTargetIndex;
    if (targetIndex < 0) {
      targetIndex = 59;
    } else if (targetIndex > 59) {
      targetIndex = 0;
    }
    const tablesEles = this.$refs["mass-entry-table"] as any;
    for (const [i, table] of this.tables.entries()) {
      const startingIndex = table.startingIndex;
      if (targetIndex >= startingIndex && targetIndex < startingIndex + 20) {
        tablesEles[i].focus(field, targetIndex);
      }
    }
    return;
  }

  performEditRows(editForm: TTEditForm) {
    if (!editForm.rowsFrom || !editForm.rowsTo) {
      return;
    }

    // make sure in range
    const rowsFrom = clamp(editForm.rowsFrom, 0, this.MAX_ROWS);
    const rowsTo = clamp(editForm.rowsTo, 0, this.MAX_ROWS);

    // make sure rowsFrom is smaller than rowsTo
    const range = [rowsFrom, rowsTo].sort((a, b) => a - b);

    for (let i = range[0] - 1; i <= range[1] - 1; i++) {
      this.entries[i].unit = editForm.unit;
      this.entries[i].mode = editForm.mode;
    }
    this.editForm = {
      rowsFrom: undefined,
      rowsTo: undefined,
      unit: undefined,
      mode: undefined,
    };
    this.showEditModal = false;
  }

  async clearEntries() {
    const { isOk } = await this.$dialog.confirm({
      title: "Clear the form?",
      text: "All existing inputs will be deleted.",
      icon: "question",
    });

    if (!isOk) {
      return;
    }
    this.doClearEntries();
  }

  doClearEntries() {
    const newEntries = [];

    for (let i = 0; i < this.MAX_ROWS; i++) {
      newEntries.push({
        day: this.selectedDay,
        number1: "",
        number2: "",
        number3: "",
        number4: "",
        number5: "",
        number6: "",
        number7: "",
        number8: "",
        number9: "",
        number10: "",
        unit: undefined,
        mode: undefined,
      });
    }
    this.entries = newEntries;
  }

  validateAllAndShowError() {
    const errors = [];
    for (const [index, entry] of this.entries.entries()) {
      const numbersValid = validateTTNumbers(entry);
      const noUnit =
        (entry.unit as any) === "" ||
        entry.unit === undefined ||
        entry.unit === 0;
      const hasMode = entry.mode;
      const numbers = [
        entry.number1,
        entry.number2,
        entry.number3,
        entry.number4,
        entry.number5,
        entry.number6,
        entry.number7,
        entry.number8,
        entry.number9,
        entry.number10,
      ];
      if (compact(numbers).length === 0 && noUnit && !hasMode) {
        continue;
      }
      if (!numbersValid) {
        errors.push(`Row ${index + 1} has invalid numbers`);
      }
      if (numbersValid && noUnit) {
        errors.push(`Row ${index + 1} has no unit`);
      }
    }
    return errors;
  }

  async handleSubmit() {
    const errors = this.validateAllAndShowError();
    if (errors.length) {
      this.$dialog.alert({
        text: errors[0],
      });
      return;
    }
    if (!this.selectedAccountId) {
      this.$dialog.alert({
        text: "No account selected",
      });
      return;
    }
    const entries = this.validEntries;

    if (!this.validEntries.length) {
      this.$dialog.alert({
        text: "Please enter number details for entries",
      });
      return;
    }

    const { isOk } = await this.$dialog.confirm({
      title: "Confirm?",
      text: "Are you sure to submit the entries for TOTO betting?",
      icon: "info",
    });

    if (isOk) {
      try {
        this.isSubmitting = true;
        const result = await this.$apollo.mutate({
          mutation: mutations.insertTtEntry,
          variables: {
            page: {
              page: this.page,
              entries,
              userId: this.selectedAccountId,
              entrySource: "massEntry",
            },
          },
        });
        this.successPageId = result.data.insertTtEntry;
        this.showPageDetailsModal = true;
        this.doClearEntries();
        this.page = "";
      } catch (err) {
        errorMutationNotification(err);
      } finally {
        this.isSubmitting = false;
      }
    }
  }

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD MMM YYYY");
  }
}
