
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { TtPage } from "@/CodegenTypes";
import { times } from "lodash";

import { TT_MODE_NAMES } from "@/constants/index";
import { queries } from "@/apollo";
import { formatCount } from "@/helpers/formatCount";
import { formatDollar } from "@/helpers/formatDollar";
import { customMoment } from "@/main";

@Component({
  apollo: {
    deletedTtPage: {
      query: queries.deletedTtPage,
      skip() {
        return !this.pageId;
      },
      variables() {
        return {
          id: this.pageId,
          releaseDate: this.releaseDate,
        };
      },
    },
  },
  components: {},
})
export default class TTPageDetailsModal extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) pageId?: TtPage["id"];

  deletedTtPage = null as null | TtPage;

  formatDollar = formatDollar;
  formatCount = formatCount;

  formatDate(dateStr: string) {
    return customMoment(dateStr).format("ddd, DD/MM/YYYY hh:mm:ssA");
  }

  startIndex = 0;
  PER_PAGE = 40;

  clickCounter = 0;

  get rowsPerTable() {
    return this.PER_PAGE / 2;
  }

  get totalEntriesCount() {
    return this.deletedTtPage?.entries.length;
  }

  get entriesInPage() {
    const endIndex = this.startIndex + this.PER_PAGE;
    return this.deletedTtPage?.entries.slice(this.startIndex, endIndex);
  }

  get entriesInFirstTable() {
    if (!this.entriesInPage) {
      return [];
    }
    const dataRows = this.entriesInPage.slice(0, this.rowsPerTable);
    const rowsToPad = this.rowsPerTable - dataRows.length - 1;
    const padRows = [] as any[];
    times(rowsToPad, () => {
      padRows.push({ number: "", unit: "", mode: "" });
    });
    return [...dataRows, ...padRows];
  }
  get entriesInSecondTable() {
    if (!this.entriesInPage) {
      return [];
    }
    const dataRows = this.entriesInPage.slice(this.rowsPerTable);
    const rowsToPad = this.rowsPerTable - dataRows.length - 1;
    const padRows = [] as any[];
    times(rowsToPad, () => {
      padRows.push({ number: "", unit: "", mode: "" });
    });
    return [...dataRows, ...padRows];
  }

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }

  convertModeValToName(val: string) {
    return TT_MODE_NAMES[val];
  }

  @Watch("value")
  reset() {
    this.startIndex = 0;
  }
}
