
import { Vue, Component, Prop } from "vue-property-decorator";
import { EntryInput } from "@/types";

export type EditForm = {
  rowsFrom: undefined | number;
  rowsTo: undefined | number;
  big: undefined | EntryInput["big"];
  small: undefined | EntryInput["small"];
  mode: undefined | EntryInput["mode"];
};

@Component
export default class EditRowsModal extends Vue {
  @Prop({ default: false }) value!: boolean;

  editForm = {
    rowsFrom: undefined,
    rowsTo: undefined,
    big: undefined,
    small: undefined,
    mode: undefined,
  } as EditForm;

  modeSelectOptions = [
    { label: "Not Edit", value: undefined },
    { label: "R", value: "R" },
    { label: "i", value: "i" },
    { label: "k", value: "k" },
  ];

  toggleModal(val: boolean) {
    this.$emit("input", val);
  }

  handleSubmit() {
    const form = this.editForm;
    if (!form.rowsFrom || !form.rowsTo) {
      return;
    }
    if (!form.big && !form.small && !form.mode) {
      return;
    }

    this.$emit("update", this.editForm);
    this.editForm.rowsFrom = undefined;
    this.editForm.rowsTo = undefined;
    this.editForm.big = undefined;
    this.editForm.small = undefined;
    this.editForm.mode = undefined;

    this.toggleModal(false);
  }
}
