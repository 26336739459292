import { User } from "@/CodegenTypes";

export const getDownlinesTreeForTreeTable = (user: User): any => {
  const children = user.downlines.map(downline =>
    getDownlinesTreeForTreeTable(downline)
  );
  return {
    key: user.userId,
    data: user.userId,
    label: `${user.userId.toUpperCase()} [${user.username}]`,
    children
  };
};
